import { handleActions } from 'redux-actions';

import { SET_FILTER, CLEAN_FILTERS, SET_BACK } from '../../constants/index';

export const filters = handleActions(
  {
    [CLEAN_FILTERS]: () => ({}),
    [SET_FILTER]: (state, action) => ({ ...state, filter: action.payload }),
    [SET_BACK]: (state, action) => ({ ...state, back: action.payload }),
  },
  {},
);
