import * as Yup from 'yup';

export const dataModel = {
  email: '',
  status: '',
};

export const validationDataModel = Yup.object().shape({
  email: Yup.string().optional('forms.validations.required'),
  status: Yup.string().optional('forms.validations.required'),
});
