import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';

import { urlCashFlowSecured, urlCashFlowMoreInfoSecured } from '../../api/urls';
import moment from 'moment-timezone';
import { PAGE_SIZE_10, FILTER_CASHFLOWS, GET_CASHFLOWS } from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += '&dateInit=' + dateInit.valueOf();
    }

    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += '&dateEnd=' + dateEnd.valueOf();
    }

    if (filters.type && filters.type !== -1) {
      filter += '&type=' + filters.type;
    }

    if (filters.uuid) {
      filter += '&uuid=' + filters.uuid;
    }

    if (filters.walletService) {
      filter += '&walletService=' + filters.walletService;
    }
  }

  return filter;
};

/**
 * Filter list Notification.
 */
export const filterCashFlows = createAction(FILTER_CASHFLOWS, (filter, param) =>
  apiGet(urlCashFlowSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Get more info.
 */
export const getCashFlowsMoreInfo = createAction(GET_CASHFLOWS, (param) =>
  apiGet(urlCashFlowMoreInfoSecured, param, true)()
);
