import React, { Component } from 'react';
import Home from './home.view';

class HomeContainer extends Component {
  componentDidMount() {}

  render() {
    return <Home />;
  }
}

HomeContainer.propTypes = {};

export default HomeContainer;
