import React from 'react';
import { RolesPermissionForm } from '@smartups-sl/react-component-library';
import Layout from '../../../../components/layouts/verticalLayout';
import PATHS from '../../../../constants/paths';
import { actions } from '../../../../api/index';
import { urlBase } from '../../../../api/urls';

const rolesPaths = {
  PERMISSIONS: PATHS.PERMISSIONS,
};

const RolesPermissionFormPage = (props) => {
  return (
    <Layout
      body={
        <>
          <RolesPermissionForm
            uuid={props.uuid}
            rolesPaths={rolesPaths}
            urlBase={urlBase}
            actions={actions}
          />
        </>
      }
    />
  );
};

RolesPermissionFormPage.propTypes = {};

export default RolesPermissionFormPage;
