import * as Yup from 'yup';
import { BLOCK_LEVEL3 } from '../../../../constants/index';

export const dataModel = {
  blockedLevel: '',
  message: '',
};

export const validationDataModel = () => {
  return Yup.object().shape({
    blockedLevel: Yup.string().required('forms.validations.required'),
    message: Yup.string().when('blockedLevel', {
      is: (value) => value && value.length > 0 && value === BLOCK_LEVEL3.code,
      then: Yup.string()
        .required('forms.validations.required')
        .max(300, {
          message: 'forms.validations.maxLength',
          args: { max: 300 },
        }),
      otherwise: Yup.string().optional(''),
    }),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
