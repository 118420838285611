import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AdminServices from '../../../../services/AdminService';
import UnilevelTree from './unilevelTree.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';
import * as GeneralServices from '../../../../services/GeneralServices';

class UnilevelTreeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      data: null,
      nodeVisited: [],
      viewType: 'simple',
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AdminServices.getUser(this.props.uuid).then((resultUser) => {
        if (resultUser?.uuid) {
          AdminServices.getUnilevelTreeNodes(resultUser.uuid).then((result) => {
            if (result) {
              this.setState({ data: result, user: resultUser });
            }
          });
        }
      });
    }
  }

  getNodes = (uuid) => {
    if (this.state.viewType === 'simple') {
      if (uuid?.info?.userUuid) {
        const firstLevel = this.state.user.treeLevel;
        const levelArrows = firstLevel % 2;
        const visited = this.state.nodeVisited.includes(uuid.info.userUuid);
        if (uuid.info.level % 2 === levelArrows && visited === false) {
          AdminServices.getUnilevelTreeNodes(uuid.info.userUuid).then((result) => {
            const newData = [...this.state.data];
            let news = false;
            result.forEach((element) => {
              const item = newData.find((ele) => ele.userUuid === element.userUuid);
              if (item === undefined) {
                newData.push(element);
                news = true;
              }
            });
            const n = [...this.state.nodeVisited];
            if (!n.includes(uuid.info.userUuid)) {
              n.push(uuid.info.userUuid);
            }
            if (news) {
              this.setState({ data: newData, nodeVisited: n });
            } else {
              this.setState({ nodeVisited: n });
            }
          });
        }
      }
    } else {
      if (uuid) {
        AdminServices.getUnilevelTreeNodes(uuid).then((result) => {
          const newData = [...this.state.data];
          let news = false;
          result.forEach((element) => {
            const item = newData.find((ele) => ele.userUuid === element.userUuid);
            if (item === undefined) {
              newData.push(element);
              news = true;
            }
          });
          const n = [...this.state.nodeVisited];
          if (!n.includes(uuid)) {
            n.push(uuid);
          }
          if (news) {
            this.setState({ data: newData, nodeVisited: n });
          } else {
            this.setState({ nodeVisited: n });
          }
        });
      }
    }
  };

  handlerOnchangeViewType = (type) => {
    this.setState({ viewType: type });
  };

  handleOnback = () => {
    GeneralServices.setFilterBack(true);
    this.props.history.push(PATHS.USER_LIST);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.user && (
          <UnilevelTree
            data={this.state.data || []}
            getNodes={this.getNodes}
            parentNode={this.state.user || undefined}
            nodeVisited={this.state.nodeVisited}
            viewType={this.state.viewType}
            onChangeType={this.handlerOnchangeViewType}
            t={t}
            onback={this.handleOnback}
          />
        )}
      </>
    );
  }
}

UnilevelTreeContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UnilevelTreeContainer));
