import React from 'react';
import FilterList from '../../../../components/administration/cashflows/cashflowsFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const CashFlowsListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

CashFlowsListPage.propTypes = {};

export default CashFlowsListPage;
