//CONSTANTS
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_25 = 25;
export const PAGE_SIZE_50 = 50;

//WALETS
export const WALLET_SERVICE = {
  BTC: 'PRUEBA1-POCKET',
};

// USER ROLE
export const ROLE_ADM = 'admin';
export const ROLE_SUPPORT = 'support';
export const ROLE_MARKETING = 'marketing';
export const ROLE_PROVIDER = 'provider';
export const ROLE_SUPPORT_PROVIDER = 'support_provider';

export const ROLES = [
  { label: 'roles.adm', value: ROLE_ADM, level: 0 },
  { label: 'roles.support', value: ROLE_SUPPORT, level: 1 },
  { label: 'roles.marketing', value: ROLE_MARKETING, level: 1 },
  { label: 'roles.provider', value: ROLE_PROVIDER, level: 1 },
  { label: 'roles.supportProvider', value: ROLE_SUPPORT_PROVIDER, level: 1 },
];

// ACTIONS AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAN_AUTH = 'CLEAN_AUTH';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SET_AUTH_PROFILE = 'SET_AUTH_PROFILE';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RECOVERY_PASS = 'RECOVERY_PASS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const GET_AVATAR = 'GET_AVATAR';
export const SET_FILTER = 'SET_FILTER';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';
export const SET_BACK = 'SET_BACK';
export const GET_CLIENT_TOKEN = 'GET_CLIENT_TOKEN';
export const ACTIVE_USER = 'ACTIVE_USER';

export const FILTER_MARKETING = 'FILTER_MARKETING';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const GET_RESOURCE = 'GET_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';

// ACTIONS USER
export const USER_INFO = 'USER_INFO';
export const CLEAN_USER = 'CLEAN_USER';

// GLOBALS ACTIONS
export const GET_NATIONALITIES = 'GET_NATIONALITIES';

// ADMIN ACTIONS
export const GET_UNILEVEL_TREE = 'GET_UNILEVEL_TREE';

export const FILTER_USERS = 'FILTER_USERS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';
export const RESET_TFA_USER = 'RESET_TFA_USER';
export const RECOVERY_TFA_USER = 'RECOVERY_TFA_USER';
export const VERIFY_2FA = 'VERIFY_2FA';

export const FILTER_BOT = 'FILTER_BOT';
export const CONFIRM_BOT = 'CONFIRM_BOT';
export const CONFIG_SUPPORT = 'CONFIG_SUPPORT';

export const PERMISSIONS_LIST = 'PERMISSIONS_LIST';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';

export const EMPLOYEE_CREATE = {
  code: 'EMPLOYEE:CREATE',
  label: 'permissions.employer.create',
};
export const EMPLOYEE_LIST = {
  code: 'EMPLOYEE:LIST',
  label: 'permissions.employer.list',
};
export const EMPLOYEE_GET = {
  code: 'EMPLOYEE:GET',
  label: 'permissions.employer.get',
};
export const EMPLOYEE_DELETE = {
  code: 'EMPLOYEE:DELETE',
  label: 'permissions.employer.delete',
};
export const EMPLOYEE_UPDATE = {
  code: 'EMPLOYEE:UPDATE',
  label: 'permissions.employer.update',
};
export const EMPLOYEE_PERMISSIONS_UPDATE = {
  code: 'EMPLOYEE_PERMISSIONS:UPDATE',
  label: 'permissions.employer.permissions',
};
export const USER_LIST = {
  code: 'USER:LIST',
  label: 'permissions.user.list',
};
export const USER_GET = {
  code: 'USER:GET',
  label: 'permissions.user.get',
};
export const USER_DELETE = {
  code: 'USER:DELETE',
  label: 'permissions.user.delete',
};
export const USER_UPDATE = {
  code: 'USER:UPDATE',
  label: 'permissions.user.update',
};
export const USER_RESET_TFA_UPDATE = {
  code: 'USER_RESET_TFA:UPDATE',
  label: 'permissions.user.resetTFA',
};
export const ADMINISTRATION_VIEW = {
  code: 'ADMINISTRATION:VIEW',
  label: 'permissions.administration.view',
};
export const TREE_VIEW = {
  code: 'TREE:VIEW',
  label: 'permissions.ceo.tree',
};

export const PERMISSIONS = [
  EMPLOYEE_LIST,
  EMPLOYEE_GET,
  EMPLOYEE_CREATE,
  EMPLOYEE_UPDATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_PERMISSIONS_UPDATE,
  USER_LIST,
  USER_DELETE,
  USER_GET,
  USER_UPDATE,
  USER_RESET_TFA_UPDATE,
  ADMINISTRATION_VIEW,
  TREE_VIEW,
];

export const ENE = { code: '0', label: 'months.ene' };
export const FEB = { code: '1', label: 'months.feb' };
export const MAR = { code: '2', label: 'months.mar' };
export const ABR = { code: '3', label: 'months.abr' };
export const MAY = { code: '4', label: 'months.may' };
export const JUN = { code: '5', label: 'months.jun' };
export const JUL = { code: '6', label: 'months.jul' };
export const AGO = { code: '7', label: 'months.ago' };
export const SEP = { code: '8', label: 'months.sep' };
export const OCT = { code: '9', label: 'months.oct' };
export const NOV = { code: '10', label: 'months.nov' };
export const DIC = { code: '11', label: 'months.dic' };

export const MESES = [ENE, FEB, MAR, ABR, MAY, JUN, JUL, AGO, SEP, OCT, NOV, DIC];

export const LANG_SPANISH = { code: 'es', label: 'language.languages.es' };
export const LANG_ENGLISH = { code: 'en', label: 'language.languages.en' };

export const LANGUAGES = [LANG_SPANISH, LANG_ENGLISH];

export const FILTER_ROLES_PERMISSION = 'FILTER_ROLES_PERMISSION';
export const UPDATE_ROLES_PERMISSION = 'UPDATE_ROLES_PERMISSION';

export const NOTIFICATION_PRIORITY_LOW = {
  code: 'LOW',
  label: 'forms.notification.priority.low',
};
export const NOTIFICATION_PRIORITY_NORMAL = {
  code: 'NORMAL',
  label: 'forms.notification.priority.normal',
};
export const NOTIFICATION_PRIORITY_HIGH = {
  code: 'HIGH',
  label: 'forms.notification.priority.high',
};

export const NOTIFICATION_PRIORITY = [
  NOTIFICATION_PRIORITY_LOW,
  NOTIFICATION_PRIORITY_NORMAL,
  NOTIFICATION_PRIORITY_HIGH,
];

// INIT NOTIFICATION CONSTANTS
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const FILTER_NOTIFICATION = 'const FILTER_NOTIFICATION';
// END NOTIFICATION CONSTANTS

export const BLOCK_LEVEL_NO_BLOCK = {
  code: '-1',
  label: 'forms.user.blockLevels.noBlock',
};
export const BLOCK_LEVEL1 = {
  code: 'L1',
  label: 'forms.user.blockLevels.l1',
};
export const BLOCK_LEVEL2 = {
  code: 'L2',
  label: 'forms.user.blockLevels.l2',
};
export const BLOCK_LEVEL3 = {
  code: 'L3',
  label: 'forms.user.blockLevels.l3',
};
export const BLOCK_LEVELS = [BLOCK_LEVEL_NO_BLOCK, BLOCK_LEVEL1, BLOCK_LEVEL2, BLOCK_LEVEL3];

export const CASHFLOW_TYPE_SUBSCRIPTION = {
  code: 1,
  label: 'forms.cashflow.type.subscription',
};
export const CASHFLOW_TYPE_COLLECTION_BROKER = {
  code: 2,
  label: 'forms.cashflow.type.collectionBroker',
};
export const CASHFLOW_TYPE_BOT_COMISSION = {
  code: 3,
  label: 'forms.cashflow.type.botComission',
};
export const CASHFLOW_TYPE_SUBS_COMISSION = {
  code: 4,
  label: 'forms.cashflow.type.subsComission',
};
export const CASHFLOW_TYPE_WITHDRAW = {
  code: 5,
  label: 'forms.cashflow.type.withdraw',
};
export const CASHFLOW_TYPE_DEPOSIT = {
  code: 6,
  label: 'forms.cashflow.type.deposit',
};
export const CASHFLOW_TYPE_ERROR_WITHDRAW = {
  code: 7,
  label: 'forms.cashflow.type.errorWithdraw',
};
export const CASHFLOW_TYPE_YIELD_COMPANY = {
  code: 8,
  label: 'forms.cashflow.type.yieldCompany',
};
export const CASHFLOW_TYPE_RETURN_PLAN_SUBSCRIPTION = {
  code: 9,
  label: 'forms.cashflow.type.returnSubscription',
};
export const CASHFLOW_TYPE_RETURN_PLAN_BOT = {
  code: 10,
  label: 'forms.cashflow.type.returnBot',
};
export const CASHFLOW_TYPE_LEADER_COMMISSION = {
  code: 11,
  label: 'forms.cashflow.type.leaderCommission',
};

export const CASHFLOW_TYPES = [
  CASHFLOW_TYPE_SUBSCRIPTION,
  CASHFLOW_TYPE_COLLECTION_BROKER,
  CASHFLOW_TYPE_BOT_COMISSION,
  CASHFLOW_TYPE_SUBS_COMISSION,
  CASHFLOW_TYPE_WITHDRAW,
  CASHFLOW_TYPE_DEPOSIT,
  CASHFLOW_TYPE_ERROR_WITHDRAW,
  CASHFLOW_TYPE_YIELD_COMPANY,
  CASHFLOW_TYPE_RETURN_PLAN_SUBSCRIPTION,
  CASHFLOW_TYPE_RETURN_PLAN_BOT,
  CASHFLOW_TYPE_LEADER_COMMISSION,
];

export const CASHFLOW_TYPE_DEFAULT = {
  code: -1,
  label: 'forms.cashflow.type.default',
};

export const CASHFLOW_FILTER_TYPES = [
  CASHFLOW_TYPE_DEFAULT,
  CASHFLOW_TYPE_SUBSCRIPTION,
  CASHFLOW_TYPE_COLLECTION_BROKER,
  CASHFLOW_TYPE_BOT_COMISSION,
  CASHFLOW_TYPE_SUBS_COMISSION,
  CASHFLOW_TYPE_WITHDRAW,
  CASHFLOW_TYPE_DEPOSIT,
  CASHFLOW_TYPE_ERROR_WITHDRAW,
  CASHFLOW_TYPE_YIELD_COMPANY,
  CASHFLOW_TYPE_RETURN_PLAN_SUBSCRIPTION,
  CASHFLOW_TYPE_RETURN_PLAN_BOT,
  CASHFLOW_TYPE_LEADER_COMMISSION,
];

export const CASH_STATUS_COM_PAYED = {
  code: 1,
  label: 'forms.cashflow.status.commissionPayed',
};
export const CASH_STATUS_WITH_SOLICITED = {
  code: 2,
  label: 'forms.cashflow.status.withdrawSolicited',
};
export const CASH_STATUS_WITH_CONFIRMED = {
  code: 3,
  label: 'forms.cashflow.status.withdrawConfirmed',
};
export const CASH_STATUS_WITH_SENT = {
  code: 4,
  label: 'forms.cashflow.status.withdrawSendt',
};

export const CASH_STATUS_WITH_SENT_ERROR = {
  code: 1000,
  label: 'forms.cashflow.status.withdrawSendtError',
};

export const CASHFLOW_STATUS = [
  CASH_STATUS_COM_PAYED,
  CASH_STATUS_WITH_SOLICITED,
  CASH_STATUS_WITH_CONFIRMED,
  CASH_STATUS_WITH_SENT,
];

// INIT CASHFLOWS CONSTANTS
export const CREATE_CASHFLOWS = 'CREATE_CASHFLOWS';
export const GET_CASHFLOWS = 'GET_CASHFLOWS';
export const UPDATE_CASHFLOWS = 'UPDATE_CASHFLOWS';
export const DELETE_CASHFLOWS = 'DELETE_CASHFLOWS';
export const FILTER_CASHFLOWS = 'FILTER_CASHFLOWS';
export const SET_COUNT_CASHFLOWS = 'SET_COUNT_CASHFLOWS';
// END CASHFLOWS CONSTANTS

export const RANKS = {
  LP0: '#335768',
  LP1: '#58abb4',
  LP2: '#c7d69e',
  LP3: '#a3cade',
  LP4: '#9db2d7',
  LP5: '#b0a2c7',
  LP6: '#ce9b96',
  LP7: '#efc492',
  LP8: '#4d829d',
  LP9: '#707070',
};

export const CATEGORY_INFO = {
  code: 'INFOR',
  label: 'documents_categories.info',
};
export const CATEGORY_MARK = {
  code: 'MARKETING',
  label: 'documents_categories.mark',
};

export const DOCUMENTS_CATEGORIES = [CATEGORY_INFO, CATEGORY_MARK];

export const BOT_STATUS_PENDING = {
  code: 'PENDING',
  label: 'forms.bot.status.pending',
};
export const BOT_STATUS_SETTING = {
  code: 'SETTING',
  label: 'forms.bot.status.setting',
};
export const BOT_STATUS_PRE_RUNNING = {
  code: 'PRE_RUNNING',
  label: 'forms.bot.status.preRunning',
};
export const BOT_STATUS_RUNNING = {
  code: 'RUNNING',
  label: 'forms.bot.status.running',
};
export const BOT_STATUS_STOPED = {
  code: 'STOPED',
  label: 'forms.bot.status.stoped',
};
export const BOT_STATUS_ERROR = {
  code: 'ERROR',
  label: 'forms.bot.status.error',
};
export const BOT_STATUS_SUPPORT_BOT = {
  code: 'SUPPORT_SETTING',
  label: 'forms.bot.status.pendingSupport',
};
export const BOT_STATUS = [
  BOT_STATUS_PENDING,
  BOT_STATUS_SETTING,
  BOT_STATUS_PRE_RUNNING,
  BOT_STATUS_RUNNING,
  BOT_STATUS_STOPED,
  BOT_STATUS_ERROR,
  BOT_STATUS_SUPPORT_BOT,
];

export const BOT_ERROR_FUNDS = {
  code: 'INSUFFICIENT_FUNDS',
  label: 'forms.bot.error.insufficient_funds',
};

export const BOT_ERROR = [BOT_ERROR_FUNDS];

export const STATUS_PENDING_VALIDATE = {
  code: 'PENDING_VALIDATE',
  label: 'forms.user.status.pendingValidate',
};
export const STATUS_PENDING_LINK = {
  code: 'PENDING_LINK',
  label: 'forms.user.status.pendingLink',
};
export const STATUS_ACTIVE = {
  code: 'ACTIVE',
  label: 'forms.user.status.active',
};
export const STATUS = [STATUS_PENDING_VALIDATE, STATUS_PENDING_LINK, STATUS_ACTIVE];

/* GENERATOR_INJECT_CONSTANTS */
