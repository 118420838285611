export const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/`;

/** AUTH **/
export const urlRolesPermisionSecured = `${urlBase}v1/adm/rolesPermission`;
export const urllogin = `${urlBase}v1/adm/auth/login`;
export const urlauthProfile = `${urlBase}v1/adm/employer/profile`;
export const urlForgotPassword = `${urlBase}v1/adm/auth/forgot`;
export const urlRecoveryPass = `${urlBase}v1/adm/auth/recovery`;
export const urlAvatar = `${urlBase}v1/auth/profile/avatar`;
export const urlauthChangePass = `${urlBase}v1/adm/employer/profile/password`;

/** USER **/
export const urlGetAvatar = `${urlBase}v1/users/{param}/avatar`;
export const urlGetClientToken = `${urlBase}v1/adm/user-s/impersonate`;

/** GLOBALS **/
export const urlGetNationalities = `${urlBase}v1/countries`;

/** ADMIN **/
export const urlFilterUser = `${urlBase}v1/adm/user-s/filter`;
export const urlGetUser = `${urlBase}v1/adm/user-s/profileUser`;
export const urlVerifyTwoFA = `${urlBase}v1/adm/user/tfa/verify`;

export const urlUpdateBlockUser = `${urlBase}v1/adm/user-s/block`;

export const urlListPermissions = `${urlBase}v1/adm/permission`;
export const urlUpdatePermissions = `${urlBase}v1/adm/employer/permission`;

export const urlGetunilevelTree = `${urlBase}v1/tree/unilevel`;

export const urlBotSecured = `${urlBase}v1/adm/bot-s`;
export const urlBotSupportSecured = `${urlBase}v1/adm/bot-s/support`;
export const urlBotErrorSecured = `${urlBase}v1/adm/bot-s/error`;

// INIT NOTIFICATION URLS
export const urlNotificationSecured = `${urlBase}v1/adm/notifications`;
// END NOTIFICATION URLS

export const urlCashFlowSecured = `${urlBase}v1/adm/cashFlow-s`;
export const urlCashFlowMoreInfoSecured = `${urlBase}v1/adm/cashFlow-s/moreInfo`;

export const urlMarketing = `${urlBase}v1/adm/marketingResources-s`;
export const urlActiveUser = `${urlBase}v1/adm/user-s/active`;
/* GENERATOR_INJECT_URLS */
