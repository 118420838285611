import { createAction } from 'redux-actions';
import { apiGet, apiPost, apiPut, apiDelete } from '../../api/index';

import { urlNotificationSecured } from '../../api/urls';

import {
  PAGE_SIZE_10,
  CREATE_NOTIFICATION,
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  FILTER_NOTIFICATION,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.message) filter += '&message=' + filters.message;
  }

  return filter;
};

/**
 * Filter list Notification.
 */
export const filterNotification = createAction(FILTER_NOTIFICATION, (filter, param) =>
  apiGet(urlNotificationSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Get Notification by uuid.
 */
export const getNotificationByUuid = createAction(GET_NOTIFICATION, (param) =>
  apiGet(urlNotificationSecured, param, true)()
);

/**
 * Create Notification.
 */
export const createNotification = createAction(CREATE_NOTIFICATION, (obj) =>
  apiPost(urlNotificationSecured, obj, undefined, true)()
);

/**
 * Update Notification.
 */
export const modifyNotification = createAction(UPDATE_NOTIFICATION, (obj, param) =>
  apiPut(urlNotificationSecured, obj, param, true)()
);

/**
 * Delete Notification by uuid.
 */
export const removeNotification = createAction(DELETE_NOTIFICATION, (param) =>
  apiDelete(urlNotificationSecured, param, true)()
);
