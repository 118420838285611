import styled from 'styled-components';

export const Rank = styled.span`
  color: #fff;
  padding: 10px 5px;
  margin-left: 15px;
  background: ${({ rankColor }) => (rankColor ? rankColor : 'transaparent')};
`;

export const RowPrincipal = styled.div`
  margin-right: 0px;
  margin-left: 0px;
  width: calc(100% - 30px);
`;

export const Row = styled.div`
  margin-right: 0px;
  margin-left: 0px;
`;
export const ColNopaddingIMG = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  max-width: 50px;
  text-align: right;
`;
export const ColNopadding = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
`;

export const RankText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
`;

export const ImageAvatar = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 0px;
`;

export const ColNopaddingRANK = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ImageRank = styled.img`
  width: 30px;
  height: 23px;
  margin-left: 0px;
`;

export const ColCenter = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
