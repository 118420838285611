import React, { useState } from 'react';
import List from '../userList';
import Filter from '../userFilter';
import { Title } from '@smartups-sl/react-component-library';
import { useTranslation } from 'react-i18next';

const EmployerListPage = (props) => {
  const { t } = useTranslation();
  const [filters, setfilters] = useState(undefined);

  const handleSetFilter = async (filter) => {
    setfilters(filter);
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 marg-btm-1rem marg-top-40px">
          <Title type="h3" variant="primary">
            {t('forms.user.title')}
          </Title>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <Filter setFilters={handleSetFilter} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <List className="marg-top-1rem" filters={filters} setFilters={handleSetFilter} />
        </div>
      </div>
    </>
  );
};

EmployerListPage.propTypes = {};

export default EmployerListPage;
