import styled from 'styled-components';

export const LabelStatus = styled.label`
  color: #ff438b;
  font-size: 0.85rem;
  margin-left: 10px;
`;

export const SwitchStatus = styled.input`
  background-color: ${({ statusCheck }) =>
    statusCheck ? '#ff438b !important' : '#fff !important'};
  border-color: ${({ statusCheck }) => (statusCheck ? '#ff438b !important' : '#bfbfbf !important')};
  box-shadow: rgb(74 74 104 / 20%) 0px 2px 2px 0px inset !important;
  /* width: 3rem !important; */
`;
