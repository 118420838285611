import React from 'react';
import FilterList from '../../../../components/administration/marketing/marketingFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const MarketingListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

MarketingListPage.propTypes = {};

export default MarketingListPage;
