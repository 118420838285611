import React from 'react';
import ForgotPassword from '../../../components/user/forgotPassword';
import Layout from '../../../components/layouts/centerLayout';

const ForgotPasswordPage = () => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <ForgotPassword />
        </>
      }
    />
  );
};

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;
