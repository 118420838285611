import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getAuthTokenSelector } from '../../store/selectors/authSelector';
import PATHS from '../../constants/paths';

class AuthRoute extends Component {
  render() {
    const { token, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (token) {
            return <Component {...props} />;
          } else {
            return <Redirect to={PATHS.LOGIN} />;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
});

export default connect(mapStateToProps, {})(AuthRoute);
