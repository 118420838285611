/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ForgotPassword from './forgotPassword.view';
import SendedForgotPassword from './forgotPasswordSended.view';
import * as AuthService from '../../../services/AuthService';
import { showDialog } from '@smartups-sl/react-utils-components';
import PATHS from '../../../constants/paths';

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSended: false,
    };
  }

  handleOnResend = () => {
    this.setState({ mailSended: false });
  };

  handleSubmit = async (values) => {
    try {
      const result = await AuthService.forgotPassword(values);
      if (result && result === 'OK') {
        this.setState({ mailSended: true });
      } else {
        const text = this.props.t('forms.forgotPassword.errors.errorSend');
        showDialog('Error', text, 'error');
      }
    } catch (error) {
      console.log('Error');
    }
  };

  handleOnBackLogin = async () => {
    this.props.history.push(PATHS.LOGIN);
  };

  render() {
    return (
      <>
        {this.state.mailSended === false ? (
          <ForgotPassword onSubmit={this.handleSubmit} />
        ) : (
          <SendedForgotPassword
            onResend={this.handleOnResend}
            onBackLogin={this.handleOnBackLogin}
          />
        )}
      </>
    );
  }
}

ForgotPasswordContainer.propTypes = {};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(ForgotPasswordContainer)));
