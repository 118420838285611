import styled from 'styled-components';
import { Link } from '@smartups-sl/react-component-library';

export const LinkActionStyled = styled(Link)`
  margin-right: 5px;
  border: 1px solid #ff4363;
  padding: 3px;
  border-radius: 5px;

  &:hover {
    background: #ff4363;
    color: #fff;
  }
`;
