import React, { useState } from 'react';
import Header from '../commons/header';
import Sidebar from '../commons/sidebar';
import { checkBootstrapTypeBreak } from '../../../utils/device/checkDevice';
import { OffCanvas, SideBarContainer, BodyContainer } from './styled';

const SimpleLayout = ({ ...prop }) => {
  const breakDevice = checkBootstrapTypeBreak();
  const [toggle, setToggle] = useState(breakDevice === 'xs' || breakDevice === 'sm' ? false : true);
  const hideShowSidebar = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <OffCanvas
        className="offcanvas offcanvas-start d-flex justify-content-between flex-wrap flex-column"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <Sidebar offCanvas />
      </OffCanvas>

      <SideBarContainer
        className={`side-navbar ${
          toggle ? 'active-nav' : ''
        }  d-flex justify-content-between flex-wrap flex-column`}
        id="sidebar"
      >
        <Sidebar />
      </SideBarContainer>

      <div className={`my-container  ${toggle ? 'active-cont' : ''}`}>
        <BodyContainer className="container-fluid">
          <Header
            showUserMenu={prop.showUserMenu !== undefined ? prop.showUserMenu : undefined}
            withSidebar={true}
            toggleFunc={() => {
              hideShowSidebar();
            }}
          />
          <div id="page-content-wrapper">
            <div id="page-content">{prop.body}</div>
          </div>
        </BodyContainer>
      </div>
    </>
  );
};

export default SimpleLayout;
