import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ChangePasswordForm from './changePassword.view';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import { getUserPersonalInfoSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';

class ChangePasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.from) {
      this.setState({ from: query.from });
    }
  }

  handleSubmit = async (values, resetForm) => {
    try {
      const result = await UserService.changePassword(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        title = this.props.t('forms.security.messages.changePass.title');
        text = this.props.t('forms.security.messages.changePass.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.security.messages.changePass.error');
        icon = 'error';
      }

      let okAction = undefined;
      if (this.state.from) {
        okAction = () => {
          this.props.history.push(this.state.from);
        };
      }
      resetForm();
      showDialog(
        title,
        text,
        icon,
        false,
        'OK',
        false,
        okAction,
        undefined,
        undefined,
        '#e10a41',
        '#272727',
      );
    } catch (error) {
      //console.log('Error', error);
    }
  };

  render() {
    return <ChangePasswordForm initialValues={this.props.info} onSubmit={this.handleSubmit} />;
  }
}

ChangePasswordContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getUserPersonalInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(ChangePasswordContainer)));
