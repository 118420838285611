import { FormFilter } from '@smartups-sl/react-complex-components';
import { InputTextFormik, InputSelectFormik } from '@smartups-sl/react-forms-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dataModel, validationDataModel } from './userFilter.data.form';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';
import { STATUS } from '../../../../constants/index';

const UserFilterForm = (props) => {
  const { t } = useTranslation();
  const { setFilters } = props;
  const [initialValues, setInitialValues] = useState(undefined);

  const filters = useSelector(getFilterSelector);
  const back = useSelector(getBackFilterSelector);

  let init = undefined;
  if (back && filters?.filters) {
    init = filters.filters;
  }

  const forceResetInitValues = () => {
    setFilters(dataModel);
    setInitialValues(dataModel);
  };

  return (
    <>
      <FormFilter
        dataModel={dataModel}
        initialValues={init ? init : initialValues}
        forceResetValues={forceResetInitValues}
        validationDataModel={validationDataModel}
        title={t('forms.user.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.user.buttons.filter')}
        textButtonClean={t('forms.user.buttons.clean')}
        formName="UserFilterForm"
        classNamefilterContainer="dark-filter-container"
        variantTitle="primary"
        animationArrowClassname="filter-arrow"
      >
        <div className="row">
          <div className="col-12">
            <Field
              id="email"
              className="form-control"
              labelField={t('forms.user.labels.email')}
              name="email"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Field
              id="status"
              labelField={t('forms.user.labels.status')}
              name="status"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={STATUS.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
            />
          </div>
        </div>
      </FormFilter>
    </>
  );
};

UserFilterForm.propTypes = {
  setFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default UserFilterForm;
