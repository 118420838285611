export const USER_PERMISSIONS = {
  /* GENERATOR_INJECT_DOMAIN */
  NOTIFICATION: 'NOTIFICATION',
  EMPLOYEE_LIST: 'EMPLOYEE_LIST',
  EMPLOYEE_CREATE: 'EMPLOYEE_CREATE',
  EMPLOYEE_UPDATE: 'EMPLOYEE_UPDATE',
  EMPLOYEE_DELETE: 'EMPLOYEE_DELETE',
  EMPLOYEE_GET: 'EMPLOYEE_GET',
  ROLES_LIST: 'ROLES_LIST',
  ROLES_CREATE: 'ROLES_CREATE',
  ROLES_UPDATE: 'ROLES_UPDATE',
  ROLES_DELETE: 'ROLES_DELETE',
  ROLES_GET: 'ROLES_GET',
  STATUS_UPDATE: 'STATUS_UPDATE',
  USER_GET: 'USER_GET',
  USER_LIST: 'USER_LIST',
  USER_CREATE: 'USER_CREATE',
  USER_UPDATE: 'USER_UPDATE',
  USER_DELETE: 'USER_DELETE',
  USER_TREE_VIEW: 'USER_TREE_VIEW',
  USER_BLOCK: 'USER_BLOCK',
  USER_IMPERSONATE: 'USER_IMPERSONATE',
  COMPLETE_EXTERNAL_BOT_INFO: 'COMPLETE_EXTERNAL_BOT_INFO',
  NOTIFICATION_LIST: 'NOTIFICATION_LIST',
  NOTIFICATION_CREATE: 'NOTIFICATION_CREATE',
  NOTIFICATION_UPDATE: 'NOTIFICATION_UPDATE',
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
  NOTIFICATION_GET: 'NOTIFICATION_GET',
  PLATFORM_CASHFLOW_LIST: 'PLATFORM_CASHFLOW_LIST',
  MARKETING_RESOURCES_LIST: 'MARKETING_RESOURCES_LIST',
  MARKETING_RESOURCES_CREATE: 'MARKETING_RESOURCES_CREATE',
  MARKETING_RESOURCES_UPDATE: 'MARKETING_RESOURCES_UPDATE',
  MARKETING_RESOURCES_DELETE: 'MARKETING_RESOURCES_DELETE',
  MARKETING_RESOURCES_GET: 'MARKETING_RESOURCES_GET',
  BOT_LIST: 'BOT_LIST',
  BOT_CREATE: 'BOT_CREATE',
  BOT_UPDATE: 'BOT_UPDATE',
  BOT_DELETE: 'BOT_DELETE',
  BOT_GET: 'BOT_GET',
  BOT_CONFIRM: 'BOT_CONFIRM',
  BOT_SUPPORT: 'BOT_SUPPORT',
};

// // INIT NOTIFICATION PERMISSIONS
// can(ACTION.VIEW, DOMAIN.NOTIFICATION);
// can(ACTION.LIST, DOMAIN.NOTIFICATION);
// can(ACTION.CREATE, DOMAIN.NOTIFICATION);
// can(ACTION.UPDATE, DOMAIN.NOTIFICATION);
// can(ACTION.DELETE, DOMAIN.NOTIFICATION);
// can(ACTION.EXECUTE, DOMAIN.NOTIFICATION);
// // END NOTIFICATION PERMISSIONS

/* GENERATOR_INJECT_PERMISSSIONS */
