import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import * as AdminServices from '../../../../services/AdminService';
import * as UserService from '../../../../services/UserService';
import * as GeneralServices from '../../../../services/GeneralServices';
import { withTranslation } from 'react-i18next';
import {
  PAGE_SIZE_10,
  BLOCK_LEVELS,
  BLOCK_LEVEL3,
  STATUS,
  STATUS_PENDING_VALIDATE,
} from '../../../../constants/index';
// import { showDialog } from '@smartups-sl/react-utils-components';
import { LinkActionStyled } from './userList.styled';
import { PaginateList } from '@smartups-sl/react-complex-components';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { Can } from '../../../permissions/Can';
import { connect } from 'react-redux';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';
import { showDialog } from '@smartups-sl/react-utils-components';

class UserListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      countries: null,
    };
  }

  componentDidMount() {
    GeneralServices.getAllNationalities().then((result) => {
      this.setState({ countries: result });
    });
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.user.table.heads.email'),
        accessor: 'email',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.rank'),
        accessor: 'rank',
        className: 'text-center',
        width: 80,
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.blockedLevel'),
        accessor: 'blockedLevel',
        className: 'text-center',
        Cell: (row) => {
          const level = BLOCK_LEVELS.find((level) => level.code === row.original.blockedLevel);
          if (level) {
            return t(level.label);
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.status'),
        accessor: 'status',
        className: 'text-center',
        Cell: (row) => {
          const status = STATUS.find((sta) => sta.code === row.original.status);
          if (status) {
            return t(status.label);
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.externalEmail'),
        accessor: 'externalEmail',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.accountId'),
        accessor: 'externalAccountId',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 120,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          {obj.original.blockedLevel !== BLOCK_LEVEL3.code && (
            <Can do={USER_PERMISSIONS.USER_BLOCK}>
              <LinkActionStyled
                to={`/adm/administration/user/block/${obj.original.uuid}`}
                type="routerDom"
                variant="terciary"
              >
                <i className="fas fa-ban" title="Bloquear usuario"></i>
              </LinkActionStyled>
            </Can>
          )}
          {obj.original.status === STATUS_PENDING_VALIDATE.code && (
            <LinkActionStyled
              variant="terciary"
              onClick={() => this.handlerActivationUser(obj.original)}
            >
              <i className="fa-solid fa-user-check" title="Validar activación" />
            </LinkActionStyled>
          )}
          <Can do={USER_PERMISSIONS.USER_TREE_VIEW}>
            <LinkActionStyled
              to={`/adm/administration/user/tree/${obj.original.uuid}`}
              type="routerDom"
              variant="terciary"
            >
              <i className="fas fa-project-diagram" title="Ver árbol"></i>
            </LinkActionStyled>
          </Can>
          <Can do={USER_PERMISSIONS.USER_IMPERSONATE}>
            <LinkActionStyled
              variant="terciary"
              onClick={() => this.handlerGoToClientView(obj.original.uuid)}
            >
              <i className="fas fa-eye" title="Vista cliente" />
            </LinkActionStyled>
          </Can>
        </div>
      </>
    );
  };

  handlerActivationUser = async (user) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await UserService.activationUser(user.uuid);
      if (result) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.user.dialog.activation.title'),
      t('forms.user.dialog.activation.text', { id: user.externalAccountId }),
      'info',
      true,
      t('forms.user.dialog.activation.buttonOk'),
      false,
      okAction,
      t('forms.user.dialog.activation.buttonNo')
    );
  };

  toList = async (filter) => {
    const { setFilters, filtersSaved, backFilter } = this.props;
    if (backFilter) {
      setFilters(filtersSaved.filters);
      filter = filtersSaved;
    }
    const result = await UserService.listFilterUser(filter);
    GeneralServices.setFilterBack(false);
    return result;
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.user.titleList')}
          defaultPageSize={PAGE_SIZE_10}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          iconAdd="fas fa-plus"
          variantTitle="primary"
          classNameTable="payper-table"
        ></PaginateList>
      </div>
    );
  }
}

UserListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  setFilters: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filtersSaved: getFilterSelector(state),
  backFilter: getBackFilterSelector(state),
});

export default connect(mapStateToProps, {})(withTranslation()(UserListContainer));
