import * as Yup from 'yup';

export const dataModel = {
  dateInit: '',
  dateEnd: '',
  type: -1,
  uuid: '',
};

export const validationDataModel = Yup.object().shape({
  dateInit: Yup.date().optional('forms.validations.required'),
  dateEnd: Yup.date().optional('forms.validations.required'),
  uuid: Yup.string().optional('forms.validations.required'),
  type: Yup.number().optional('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
