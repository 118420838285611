import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import LoginPage from './pages/user/login';
import MyProfile from './pages/user/myProfile';
import ForgotPassword from './pages/user/forgotPassword';
import RecoveryPass from './pages/user/recoveryPassword';
import HomePage from './pages/user/home';
import ManageSecurity from './pages/user/manageSecurity';

import AdminRoutes from './Routes/AdministrationRoutes';
import AuthRoute from './Routes/Security/AuthRoute';
import AuthAdminRoute from './Routes/Security/AuthAdminRoute';
// import ErrorPage from './components/core/globals/ErrorPage';
import PATHS from './constants/paths';
import { AbilityContext } from './components/permissions/Can';
import { buildAbilityFor } from './components/permissions/ability';

const ability = buildAbilityFor();

class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <AbilityContext.Provider value={ability}>
        <Router>
          <Switch>
            <Route exact path={PATHS.LOGIN} component={LoginPage} />

            <AuthRoute exact path={PATHS.ADMIN_HOME} component={HomePage} />
            <Route exact path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
            <AuthRoute exact path={PATHS.MY_PROFILE} component={MyProfile} />
            <AuthRoute exact path={PATHS.SECURITY} component={ManageSecurity} />
            <Route
              exact
              path={PATHS.RECOVERY_PASSWORD}
              component={(props) => <RecoveryPass token={props.match.params.token} />}
            />

            <AuthAdminRoute path={PATHS.ADMIN} component={AdminRoutes} />
            {/* GENERATOR_INJECT_ROUTES */}

            {/* <Route exact path={PATHS.LOGOUT} component={LogoutContainer} />
        <Route exact path={PATHS.ERROR} component={ErrorPage} />  */}
            <Route path={PATHS.INIT} render={() => <Redirect to={PATHS.LOGIN} />} />
          </Switch>
        </Router>
      </AbilityContext.Provider>
    );
  }
}

App.propTypes = {};

export default App;
