import React from 'react';
import List from '../../../../components/administration/user/userFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const UserListPage = (props) => {
  return (
    <Layout
      body={
        <>
          <List />
        </>
      }
    />
  );
};

UserListPage.propTypes = {};

export default UserListPage;
