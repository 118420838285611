import * as Yup from 'yup';

export const dataModel = {
  name: '',
  enabled: false,
  doc: '',
  lang: '',
  category: '',
};

export const validationDataModel = (edit) => {
  if (edit) {
    return Yup.object().shape({
      name: Yup.string().required('forms.validations.required'),
      enabled: Yup.boolean(),
      doc: Yup.string().optional('forms.validations.required'),
      lang: Yup.string().required('forms.validations.required'),
      category: Yup.string().required('forms.validations.required'),
    });
  }

  return Yup.object().shape({
    name: Yup.string().required('forms.validations.required'),
    enabled: Yup.boolean(),
    doc: Yup.string().required('forms.validations.required'),
    lang: Yup.string().required('forms.validations.required'),
    category: Yup.string().required('forms.validations.required'),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
