import { store } from '../store/index';
import {
  filterNotification,
  removeNotification,
  modifyNotification,
  getNotificationByUuid,
  createNotification,
} from '../store/actions/NotificationActions';

/**
 * Create Notification
 */
export const addNotification = async (values) => {
  const objSend = { ...values };
  const result = await store.dispatch(createNotification(objSend));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Get Notification
 */
export const getNotification = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getNotificationByUuid(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update Notification
 */
export const updateNotification = async (values, uuid) => {
  const objSend = { ...values };
  const param = [uuid];
  const result = await store.dispatch(modifyNotification(objSend, param));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * List Notification
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterNotification(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Delete Notification
 */
export const deleteNotification = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(removeNotification(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
