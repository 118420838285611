import { store } from '../store/index';
import {
  filterBot,
  confirmBotRunning,
  updateBot,
  pastToSupportConfig,
} from '../store/actions/BotActions';

/**
 * List Bot
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterBot(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Bot confirm
 */
export const confirmBot = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(confirmBotRunning(param));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Bot confirm
 */
export const passToSupport = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(pastToSupportConfig(param));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Error bot
 */
export const errorBot = async (botUuid, error) => {
  const objSend = {
    error,
  };
  const result = await store.dispatch(updateBot(objSend, [botUuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};
