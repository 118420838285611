import { createAction } from 'redux-actions';
import { apiGet, apiPost, apiPut, apiDelete } from '../../api/index';
import {
  urlVerifyTwoFA,
  urlGetunilevelTree,
  urlGetUser,
  urlUpdateBlockUser,
  urlMarketing,
} from '../../api/urls';
import {
  VERIFY_2FA,
  GET_UNILEVEL_TREE,
  UPDATE_USER,
  FILTER_MARKETING,
  CREATE_RESOURCE,
  GET_RESOURCE,
  UPDATE_RESOURCE,
  DELETE_RESOURCE,
  PAGE_SIZE_10,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.name) filter += '&name=' + filters.name;
    if (filters.lang) filter += '&lang=' + filters.lang;
    if (filters.category) filter += '&category=' + filters.category;
  }

  return filter;
};

/**
 * Verify 2fa.
 */
export const verifyTfa = createAction(VERIFY_2FA, (obj, param) =>
  apiPost(urlVerifyTwoFA, obj, param, true)()
);

/**
 * Get unilevel tree.
 */
export const getUnilevelTree = createAction(GET_UNILEVEL_TREE, (param) =>
  apiGet(urlGetunilevelTree, param, true)()
);

/**
 * Get Client token.
 */
export const getUserByUuid = createAction('GET_USER_BY_UUID', (param) =>
  apiGet(urlGetUser, param, true)()
);

/**
 * Block User.
 */
export const updateBlockUser = createAction(UPDATE_USER, (obj, params) =>
  apiPut(urlUpdateBlockUser, obj, params, true)()
);

/**
 * Create Resource.
 */
export const createResource = createAction(CREATE_RESOURCE, (obj) =>
  apiPost(urlMarketing, obj, undefined, true)()
);

/**
 * Get Resource by uuid.
 */
export const getResourceByUuid = createAction(GET_RESOURCE, (param) =>
  apiGet(urlMarketing, param, true)()
);

/**
 * Update Resource.
 */
export const updateResource = createAction(UPDATE_RESOURCE, (obj, param) =>
  apiPut(urlMarketing, obj, param, true)()
);

/**
 * Delete resource by uuid.
 */
export const deleteResource = createAction(DELETE_RESOURCE, (param) =>
  apiDelete(urlMarketing, param, true)()
);

/**
 * Filter list resources.
 */
export const filterMarketing = createAction(FILTER_MARKETING, (filter, param) =>
  apiGet(urlMarketing + generateFilterQuery(filter), param, true)()
);
