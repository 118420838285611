import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import PATHS from '../../constants/paths';
// import NotificationFormPage from '../pages/notification/form';
import CashflowsListPage from '../../pages/administration/cashflows/list';
/* GENERATOR_INJECT_IMPORT_PAGE */

class CashflowsRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute path={PATHS.PLATFORM_CASHFLOWS_LIST} component={CashflowsListPage} />
        {/* GENERATOR_INJECT_LIST_ROUTE */}
        {/* <AuthRoute exact path={PATHS.CREATE_NOTIFICATION} component={NotificationFormPage} />
        <AuthRoute
          exact
          path={PATHS.UPDATE_NOTIFICATION}
          component={(props) => <NotificationFormPage uuid={props.match.params.uuid} />}
        /> */}

        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

CashflowsRoutesContainer.propTypes = {};

export default CashflowsRoutesContainer;
