import React from 'react';
import PropTypes from 'prop-types';
import BlockForm from '../../../../components/administration/user/userBlockForm';
import Layout from '../../../../components/layouts/verticalLayout';

const UserBlockFormPage = (props) => {
  return (
    <Layout
      body={
        <>
          <BlockForm uuid={props.uuid} />
        </>
      }
    />
  );
};

UserBlockFormPage.propTypes = {
  uuid: PropTypes.string,
};

export default UserBlockFormPage;
