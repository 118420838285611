import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RecoveryForm from './recoveryPassword.view';
import SimpleLayout from '../../layouts/simpleLayout';
import * as AuthService from '../../../services/AuthService';
import { showDialog } from '@smartups-sl/react-utils-components';
import PATHS from '../../../constants/paths';

class RecoveryPassContainer extends Component {
  handleSubmit = async (values) => {
    try {
      if (this.props.token) {
        values.token = this.props.token;
        const result = await AuthService.recoveryPassword(values);
        if (result && result === 'OK') {
          let okAction = undefined;
          okAction = () => {
            this.props.history.push(PATHS.LOGIN);
          };

          showDialog(
            this.props.t('forms.recovery.messages.success.title'),
            this.props.t('forms.recovery.messages.success.text'),
            'success',
            false,
            'OK',
            false,
            okAction
          );
        } else {
          const text = this.props.t('forms.recovery.errors.general');
          showDialog('Error', text, 'error');
        }
      } else {
        this.props.history.push(PATHS.LOGIN);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  render() {
    return <RecoveryForm onSubmit={this.handleSubmit} />;
  }
}

export default withRouter(withTranslation()(RecoveryPassContainer));
