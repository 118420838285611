import { handleActions } from 'redux-actions';

// import { } from '../../constants/index';

export const notifications = handleActions(
  {
    // [CLEAN_FILTERS]: () => ({}),
  },
  {},
);
