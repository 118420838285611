import { createSelector } from 'reselect';

const getStateFiltersSelector = (state) => state.filters;

export const getFilterSelector = createSelector(getStateFiltersSelector, (filters) => {
  if (filters && filters.filter) {
    return filters.filter;
  }

  return undefined;
});

export const getBackFilterSelector = createSelector(getStateFiltersSelector, (filters) => {
  if (filters && filters.back !== undefined) {
    return filters.back;
  }

  return undefined;
});
