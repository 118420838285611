import React from 'react';
import { RolesPermissionFilterList } from '@smartups-sl/react-component-library';
import Layout from '../../../../components/layouts/verticalLayout';

import { actions } from '../../../../api/index';
import { urlBase } from '../../../../api/urls';

const rolesPaths = {
  UPDATE_ROLES: '/adm/administration/permissions/form/update',
};

const RolesPermisionListPage = (props) => {
  return (
    <Layout
      body={
        <RolesPermissionFilterList rolesPaths={rolesPaths} urlBase={urlBase} actions={actions} />
      }
    />
  );
};

RolesPermisionListPage.propTypes = {};

export default RolesPermisionListPage;
