import React from 'react';
import Header from '../commons/header';
import { CenterPageContent } from './styled';
import { connect } from 'react-redux';
import { getAuthTokenSelector, getUserRoleSelector } from '../../../store/selectors/authSelector';

const CenterLayout = (props) => {
  const { token, role } = props;
  return (
    <div id="sb-site">
      <div id="page-wrapper-login" className="container-fluid">
        <Header
          type="login"
          showUserMenu={props.showUserMenu !== undefined ? props.showUserMenu : undefined}
          role={role}
          token={token}
        />
        <CenterPageContent id="page-content">{props.body}</CenterPageContent>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  role: getUserRoleSelector(state),
  // timeZone: getTimeZoneSelector(state),
});

export default connect(mapStateToProps, {})(CenterLayout);
