import * as Yup from 'yup';

export const dataModel = {
  name: '',
  surname: '',
  avatar: '',
};

export const validationDataModel = Yup.object().shape({
  name: Yup.string().required('forms.validations.required'),
  surname: Yup.string().required('forms.validations.required'),
  avatar: Yup.string().optional('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  if (initialValues) {
    const items = Object.keys(dataModel);
    items.forEach((item) => {
      if (initialValues[item]) {
        dataModel[item] = initialValues[item];
      }
    });
    return dataModel;
  }

  return dataModel;
};
