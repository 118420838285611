import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
// import { ignoreActions } from 'redux-ignore';

import { auth } from './auth';
import { user } from './user';
import { filters } from './filters';
import { products } from './product';
// INIT BOT_PROVIDER URLS
import { botproviders } from './botProvider';
// END BOT_PROVIDER URLS
// INIT SERVER URLS
import { servers } from './server';
// END SERVER URLS
// INIT NOTIFICATION URLS
import { notifications } from './notification';
// END NOTIFICATION URLS
/* GENERATOR_INJECT_IMPORT_REDUCER */

export default combineReducers({
  /* GENERATOR_INJECT_ADD_REDUCER */
  notifications,
  servers,
  botproviders,
  modal,
  products,
  filters,
  auth,
  user,
});
