import mypocketBotTheme from './mypocketbot';
import defaultTheme from './default';

export const getTheme = (themeName = 'default') => {
  if (themeName === 'mypocketbot') {
    return mypocketBotTheme;
  } else {
    return defaultTheme;
  }
};
