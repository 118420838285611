import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class ModalInfo extends Component {
  componentDidMount() {}

  render() {
    const { show, handleHide, body } = this.props;
    return (
      <Modal size="lg" show={show} onHide={handleHide} centered backdrop="static" keyboard={false}>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    );
  }
}

ModalInfo.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
};

export default connectModal({ name: 'ModalForm' })(withTranslation()(ModalInfo));
