import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AdminServices from '../../../../services/AdminService';
import UserBlockForm from './userBlockForm.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@smartups-sl/react-utils-components';
import * as GeneralServices from '../../../../services/GeneralServices';
import { BLOCK_LEVEL3 } from '../../../../constants';

class UserBlockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: null,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AdminServices.getUser(this.props.uuid).then((result) => {
        console.log(result);
        if (result?.uuid) {
          this.setState({ values: result });
        }
      });
    }
  }

  blockUser = async (values) => {
    const { t } = this.props;
    const result = await AdminServices.blockUser(values, this.props.uuid);

    let title = '';
    let text = '';
    let icon = '';
    if (result && result === 'OK') {
      title = t('forms.user.messages.blocked.title');
      text = t('forms.user.messages.blocked.success');
      icon = 'success';
    } else {
      title = 'Error';
      text = t('forms.user.messages.blocked.error');
      icon = 'error';
    }

    let okAction = undefined;
    okAction = () => {
      this.props.history.push(PATHS.USER_LIST);
    };
    showDialog(title, text, icon, false, 'OK', false, okAction);
  };

  handleSubmit = async (values) => {
    const { t } = this.props;
    try {
      if (values && this.props.uuid) {
        if (values.blockedLevel === BLOCK_LEVEL3.code) {
          const okAction = async () => {
            this.blockUser(values);
          };

          showDialog(
            t('forms.user.dialog.block.title'),
            t('forms.user.dialog.block.text'),
            'warning',
            true,
            t('forms.user.dialog.block.buttonOk'),
            false,
            okAction,
            t('forms.user.dialog.block.buttonNo')
          );
        } else {
          this.blockUser(values);
        }
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    GeneralServices.setFilterBack(true);
    this.props.history.push(PATHS.USER_LIST);
  };

  render() {
    return (
      <UserBlockForm
        initialValues={this.state.values}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
      />
    );
  }
}

UserBlockContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UserBlockContainer));
