import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import * as AuthService from '../../../../../../services/AuthService';
import PATHS from '../../../../../../constants/paths';
import { Button } from '@smartups-sl/react-component-library';
import { IconStyled } from './styled';
import Menu, { Item as MenuItem } from 'rc-menu';
import { AbilityContext } from '../../../../../permissions/Can';

class UserMenuHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  switchShowMenu = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  onClick = async (info) => {
    const { key } = info;
    this.switchShowMenu();

    switch (key) {
      case 'home':
        let route = PATHS.ADMIN_HOME;
        this.props.history.push(route);
        break;
      case 'my_profile':
        this.props.history.push(PATHS.MY_PROFILE);
        break;
      case 'close_session':
        await AuthService.logout();
        this.props.history.push(PATHS.LOGIN);
        break;
      case 'administration':
        this.props.history.push(PATHS.ADMINISTRATIONS);
        break;
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="buttonMenu">
          <Button type="button" variant="trasparent" onClick={() => this.switchShowMenu()}>
            <i className="fas fa-bars" />
          </Button>
        </div>
        <CSSTransition
          in={this.state.showMenu}
          timeout={400}
          classNames="menu-transition"
          unmountOnExit
          appear
        >
          <div className="menuContainer">
            <Menu mode="inline" onClick={this.onClick}>
              <MenuItem key="home">
                <IconStyled className="fas fa-home" />
                {t('home')}
              </MenuItem>
              <MenuItem key="my_profile">
                <IconStyled className="fas fa-user-edit" />
                {t('my_profile')}
              </MenuItem>

              <MenuItem key="close_session">
                <IconStyled className="fas fa-power-off" />
                {t('close_session')}
              </MenuItem>
            </Menu>
          </div>
        </CSSTransition>
      </>
    );
  }
}

UserMenuHeader.contextType = AbilityContext;

UserMenuHeader.propTypes = {
  registerPayed: PropTypes.bool,
  role: PropTypes.string,
};

export default withRouter(withTranslation()(UserMenuHeader));
