import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as BotService from '../../../../services/BotService';
import { withTranslation } from 'react-i18next';
import { PAGE_SIZE_10, STATUS_CREATED } from '../../../../constants/index';
import { showDialog } from '@smartups-sl/react-utils-components';
// import PATHS from '../../../../constants/paths';
import { LinkActionStyled, ErrorIcon, StrategyCode } from './botList.styled';
import { Can, AbilityContext } from '../../../permissions/Can';
import { PaginateList } from '@smartups-sl/react-complex-components';
import moment from 'moment-timezone';
import { BOT_STATUS } from '../../../../constants/index';
import { connect } from 'react-redux';
import { show, hide } from 'redux-modal';
import ErrorForm from './errorBotForm';
import ReactTooltip from 'react-tooltip';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class BotListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.bot.table.heads.createdAt'),
        accessor: 'createdAt',
        className: 'text-center',
        Cell: (row) => {
          const date = moment(row.original.createdAt);
          return date.tz(moment.tz.guess()).format('DD/MM/yyyy HH:mm');
        },
      },
      // {
      //   Header: this.getHeaderTable('forms.bot.table.heads.subscribedUntil'),
      //   accessor: 'subscribedUntil',
      //   className: 'text-center',
      //   Cell: (row) => {
      //     const date = moment(row.original.subscribedUntil);
      //     return date.tz(moment.tz.guess()).format('DD/MM/yyyy');
      //   },
      // },
      {
        Header: this.getHeaderTable('forms.bot.table.heads.type'),
        accessor: 'botTypeItem.name',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.bot.table.heads.minAmount'),
        accessor: 'botTypeItem.minAmount',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.bot.table.heads.accountId'),
        accessor: 'accountId',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.bot.table.heads.strategy'),
        accessor: 'accountId',
        className: 'text-center',
        Cell: (row) => {
          return (
            <>
              <CopyToClipboard
                text={row.original.botTypeItem.strategy}
                onCopy={() => {
                  toast(t('strategyCopied'), {
                    position: 'top-center',
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                  });
                }}
              >
                <span>
                  <StrategyCode className="fas fa-copy"></StrategyCode>
                  {` ${row.original.botTypeItem.strategy}`}
                </span>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        Header: this.getHeaderTable('forms.bot.table.heads.status'),
        accessor: 'status',
        className: 'text-center',
        Cell: (row) => {
          const status = BOT_STATUS.find((sta) => sta.code === row.original.status);
          if (status) {
            return status.code === 'ERROR' ? (
              <>
                <ReactTooltip
                  id={`Error_${row.original.uuid}`}
                  type="dark"
                  effect="float"
                  place="left"
                  className="tooltipErrorStatus"
                  multiline
                  // delayHide={99999999999999999}
                >
                  <div className="row">
                    <div className="col-12">
                      {t(`forms.bot.error.${row.original.errorCode.toLowerCase()}`)}
                    </div>
                  </div>
                </ReactTooltip>
                <div data-tip data-for={`Error_${row.original.uuid}`}>
                  <ErrorIcon className="fa-solid fa-circle-exclamation"></ErrorIcon>
                  {t(status.label)}
                </div>
              </>
            ) : (
              t(status.label)
            );
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 150,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    return (
      <>
        <div className="text-center">
          <Can do={USER_PERMISSIONS.BOT_SUPPORT}>
            {obj.original.status === 'PRE_RUNNING' && (
              <LinkActionStyled
                variant="terciary"
                onClick={() => this.handlerPassToSupport(obj.original.uuid)}
              >
                <i className="fa-solid fa-screwdriver-wrench" title="Enviar para configurar" />
              </LinkActionStyled>
            )}
          </Can>
          <Can do={USER_PERMISSIONS.BOT_CONFIRM}>
            {obj.original.status === 'SUPPORT_SETTING' && (
              <LinkActionStyled
                variant="terciary"
                onClick={() => this.handlerConfirmBot(obj.original.uuid)}
              >
                <i className="fa-solid fa-square-check" title="Confirmar bot" />
              </LinkActionStyled>
            )}
          </Can>
          {(obj.original.status === 'RUNNING' ||
            obj.original.status === 'PRE_RUNNING' ||
            obj.original.status === 'SUPPORT_SETTING') && (
            <LinkActionStyled
              variant="terciary"
              onClick={() => this.handlerSendError(obj.original)}
            >
              <i className="fa-solid fa-circle-exclamation" title="Notificar Error" />
            </LinkActionStyled>
          )}
        </div>
      </>
    );
  };

  handleSubmitError = async (values, bot) => {
    const { t } = this.props;
    if (values) {
      const result = await BotService.errorBot(bot.uuid, values.error);
      if (result) {
        showDialog(
          t('forms.bot.messages.error.title'),
          t('forms.bot.messages.error.success'),
          'success',
          false,
          'OK',
          false
        );
      } else {
        showDialog(
          t('forms.bot.messages.error.title'),
          t('forms.bot.messages.error.error'),
          'error',
          false,
          'OK',
          false
        );
      }
    }
    this.setState({ refreshData: true });
    this.handlerClosePopup('ModalForm');
  };

  handlerClosePopup = (popupName) => {
    this.props.hide(popupName);
  };

  handlerSendError = (bot) => {
    this.props.show('ModalForm', {
      body: (
        <>
          <ErrorForm
            onSubmit={(values) => this.handleSubmitError(values, bot)}
            cancelAction={() => this.handlerClosePopup('ModalForm')}
          />
        </>
      ),
    });
  };

  handlerPassToSupport = (value) => {
    const { t } = this.props;
    const okAction = async () => {
      const result = await BotService.passToSupport(value);
      if (result && result === 'OK') {
        this.setState({ refreshData: true });
      }
    };
    showDialog(
      t('forms.bot.dialog.support.title'),
      t('forms.bot.dialog.support.text'),
      'warning',
      true,
      t('forms.bot.dialog.support.buttonOk'),
      false,
      okAction,
      t('forms.bot.dialog.support.buttonNo')
    );
  };

  handlerConfirmBot = (value) => {
    const { t } = this.props;
    const okAction = async () => {
      const result = await BotService.confirmBot(value);
      if (result && result === 'OK') {
        this.setState({ refreshData: true });
      }
    };
    showDialog(
      t('forms.bot.dialog.confirm.title'),
      t('forms.bot.dialog.confirm.text'),
      'warning',
      true,
      t('forms.bot.dialog.confirm.buttonOk'),
      false,
      okAction,
      t('forms.bot.dialog.confirm.buttonNo')
    );
  };

  toList = async (filter) => {
    const result = await BotService.listFilter(filter);
    return result;
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.bot.titleList')}
          defaultPageSize={PAGE_SIZE_10}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          iconAdd="fas fa-plus"
          classNameLinkAdd="actionAdd"
          variantTitle="primary"
          floatButton
          variantIcon="primary"
          classNameTable="payper-table"
        ></PaginateList>
      </div>
    );
  }
}

BotListContainer.contextType = AbilityContext;

BotListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { show, hide })(withTranslation()(BotListContainer));
