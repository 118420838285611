import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import { InputTextFormik, InputSelectFormik } from '@smartups-sl/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './notificationForm.data.form';
import { NOTIFICATION_PRIORITY } from '../../../../constants/index';
import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';

const NotificationForm = (props) => {
  const { t, handleSubmit, onback, edit } = props;
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <div className="row marg-btm-1rem marg-top-40px">
            <div className="col-12">
              <Title type="h3" variant="primary">
                {edit ? t('forms.notification.titleEdit') : t('forms.notification.titleNew')}
              </Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="message"
                  className="form-control"
                  labelField={t('forms.notification.labels.message')}
                  name="message"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="priority"
                  labelField={t('forms.notification.labels.priority')}
                  name="priority"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={NOTIFICATION_PRIORITY.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  mandatory
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-md"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.notification.buttons.back')}
                </Button>
                <Button type="submit" className="btn btn-md" variant="primary">
                  {t('forms.notification.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NotificationForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
};

const NotificationFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'NotificationForm',
})(withTranslation()(NotificationForm));

export default NotificationFormF;
