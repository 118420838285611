import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import PATHS from '../../constants/paths';
import NotificationFormPage from '../../pages/administration/notification/form';
import NotificationListPage from '../../pages/administration/notification/list';
/* GENERATOR_INJECT_IMPORT_PAGE */

class NotificationRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute path={PATHS.NOTIFICATION_LIST} component={NotificationListPage} />
        {/* GENERATOR_INJECT_LIST_ROUTE */}
        <AuthAdminRoute exact path={PATHS.CREATE_NOTIFICATION} component={NotificationFormPage} />
        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_NOTIFICATION}
          component={(props) => <NotificationFormPage uuid={props.match.params.uuid} />}
        />

        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

NotificationRoutesContainer.propTypes = {};

export default NotificationRoutesContainer;
