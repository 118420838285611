import * as Yup from 'yup';

export const dataModel = {
  email: '',
};

export const validationDataModel = Yup.object().shape({
  email: Yup.string()
    .required('forms.validations.required')
    .email('forms.validations.email'),
});
