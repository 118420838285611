import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as AdminServices from '../../../../services/AdminService';
import { withTranslation } from 'react-i18next';
import { PAGE_SIZE_10 } from '../../../../constants/index';
import { showDialog } from '@smartups-sl/react-utils-components';
import PATHS from '../../../../constants/paths';
import { LinkActionStyled, LinkAction2Styled } from './marketingList.styled';
import { PaginateList } from '@smartups-sl/react-complex-components';
import { LANGUAGES, DOCUMENTS_CATEGORIES } from '../../../../constants/index';
import { Can, AbilityContext } from '../../../permissions/Can';

class MarketingListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.marketing.table.heads.name'),
        accessor: 'name',
      },
      {
        Header: this.getHeaderTable('forms.marketing.table.heads.category'),
        accessor: 'category',
        className: 'align-self-center text-center',
        Cell: (row) => {
          const cat = DOCUMENTS_CATEGORIES.find(
            (element) => element.code === row.original.category
          );
          return cat ? t(cat.label) : row.original.category;
        },
      },
      {
        Header: this.getHeaderTable('forms.marketing.table.heads.lang'),
        accessor: 'lang',
        className: 'align-self-center text-center',
        Cell: (row) => {
          const lang = LANGUAGES.find((element) => element.code === row.original.lang);
          return lang ? t(lang.label) : row.original.lang;
        },
      },
      {
        Header: this.getHeaderTable('forms.marketing.table.heads.publish'),
        accessor: 'enabled',
        className: 'align-self-center text-center',
        Cell: (row) => {
          if (row.original.enabled === true) {
            return t('publish');
          }

          return t('no_publish');
        },
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 150,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    console.log(obj);
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          {/* <Can do={ACTIONS.UPDATE} on={DOMAINS.MARKETING}> */}
          <LinkActionStyled
            to={`/adm/administration/resources/form/update/${obj.original.uuid}`}
            type="routerDom"
            variant="terciary"
          >
            <i className="fas fa-edit" title="Editar" />
          </LinkActionStyled>
          {/* </Can> */}
          {/* <Can do={ACTIONS.DELETE} on={DOMAINS.MARKETING}> */}
          <LinkActionStyled
            variant="terciary"
            onClick={() => this.handlerRemoveResource(obj.original.uuid)}
          >
            <i className="far fa-trash-alt" title="Eliminar" />
          </LinkActionStyled>
          {/* </Can> */}
          <LinkAction2Styled href={obj.original.path} title="Descargar" variant="terciary">
            <i className="fas fa-file-download" />
          </LinkAction2Styled>
        </div>
      </>
    );
  };

  handlerRemoveResource = (value) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await AdminServices.delResource(value);
      if (result && result.deleted === true) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.marketing.dialog.remove.title'),
      t('forms.marketing.dialog.remove.text'),
      'warning',
      true,
      t('forms.marketing.dialog.remove.buttonOk'),
      false,
      okAction,
      t('forms.marketing.dialog.remove.buttonNo')
    );
  };

  toList = async (filter) => {
    const result = await AdminServices.listFilterResources(filter);
    return result;
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.marketing.titleList')}
          formlinkToAdd={PATHS.CREATE_MARKETING}
          defaultPageSize={PAGE_SIZE_10}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          iconAdd="fas fa-plus"
          variantTitle="primary"
          classNameTable="payper-table"
          classNameLinkAdd="actionAdd"
          floatButton
        ></PaginateList>
      </div>
    );
  }
}

MarketingListContainer.contextType = AbilityContext;

MarketingListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
};

export default withTranslation()(MarketingListContainer);
