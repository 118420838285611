import PATHS from '../../constants/paths';
import { createBrowserHistory } from 'history';
import { showDialog } from '@smartups-sl/react-utils-components';
import i18next from 'i18next';

const getErrorMessage = (code) => {
  return i18next.t(`server.errors.${code.toLowerCase()}`);
};

const showErrorDialog = (codeErrorMessage, titleMessage, detail) => {
  // let text = `<div class="row"><div class="col-12">Error code: ${codeError}</div></div>`;
  let text = '';
  if (codeErrorMessage) {
    text += `<div class="row no-margin"><div class="col-12">${getErrorMessage(
      codeErrorMessage
    )}</div></div>`;
  } else {
    text += `<div class="row no-margin"><div class="col-12">${getErrorMessage(
      titleMessage
    )}</div></div>`;
  }
  if (detail) {
    text += `<div class="row no-margin"><div class="col-12">${detail}</div></div>`;
  }

  showDialog(
    'Error',
    text,
    'error',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    '#e81d75',
    '#272727'
  );
};

const createBodyErrorDetail = (details) => {
  let detail = '<ul>';
  details.forEach((det) => {
    det.messages.forEach((mens) => {
      detail += `<li>${mens}</li>`;
    });
  });
  detail += '</ul>';
  return detail;
};

const createBodyErrorDetailJoi = (details) => {
  let detail = '<ul>';
  details.body.forEach((det) => {
    // det.messages.forEach((mens) => {
    detail += `<li>${det.message}</li>`;
    // });
  });
  detail += '</ul>';
  return detail;
};

export const handleErrorResponse = (error) => {
  const { response } = error;
  if (
    response.status === 401 &&
    (response.data === 'Unauthorized' ||
      response.data.error.message !== 'USER.LOGIN.EMAIL_OR_PASSWORD_INCORRECT')
  ) {
    if (response.data === 'Unauthorized') {
      createBrowserHistory().push(PATHS.LOGIN);
      window.location.reload();
    } else {
      const errorData = response.data.error;
      showErrorDialog(undefined, errorData.message, undefined);
      // createBrowserHistory().push(PATHS.LOGIN);
      // window.location.reload();
    }
  } else if (response.status === 412) {
    createBrowserHistory().push(PATHS.SECURITY_TFA);
    window.location.reload();
  } else if (
    response.status === 403 &&
    response.data.error.code === 'USER.LOGIN.EMAIL_NOT_VERIFIED'
  ) {
    createBrowserHistory().push(`${PATHS.CONFIRM_REGISTER}`);
    window.location.reload();
    // } else if (
    //   response.status === 404 &&
    //   response.data.error.code === 'CASHFLOW.NOT_FOUND'
    // ) {
  } else {
    if (response.data && response.data.error && response.status !== 412) {
      let error;
      let codeErrorMessage;
      let detail = null;
      let messageServer;
      if (
        response.data.name &&
        response.data.name === 'ValidationError' &&
        response.status === 400
      ) {
        error = response.data;
        if (error.details && error.details.body.length > 0) {
          detail = createBodyErrorDetailJoi(error.details);
          messageServer = 'validations';
        }
      } else {
        error = response.data.error;
        codeErrorMessage = error.code;
        if (error.details && error.details.length > 0) {
          detail = createBodyErrorDetail(error.details);
        }
        messageServer = error.message;
      }
      let specialError = false;
      if (error?.code?.date) {
        specialError = true;
      }
      if (!specialError) {
        showErrorDialog(codeErrorMessage, messageServer, detail);
      }
    }
  }
};
