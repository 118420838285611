/* eslint-disable indent */
import React from 'react';
import Login from '../../../components/user/login';
import Layout from '../../../components/layouts/centerLayout';

const LoginPage = () => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <Login />
        </>
      }
    />
  );
};

LoginPage.propTypes = {};

export default LoginPage;
