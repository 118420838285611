const PATHS = {};

PATHS.INIT = '/';
PATHS.ERROR = '/error';
PATHS.LOGOUT = '/logout';
PATHS.LOGIN = '/login';
PATHS.RECOVERY_PASSWORD = '/recovery/password/:token';
PATHS.ADMIN = '/adm';
PATHS.FORGOT_PASSWORD = '/forgotPassword';
PATHS.MY_PROFILE = '/myProfile';
PATHS.ADMIN_HOME = '/home';
PATHS.SECURITY = '/security';

PATHS.EMPLOYER = '/adm/administration/employer';
PATHS.EMPLOYER_LIST = '/adm/administration/employer/list';
PATHS.CREATE_EMPLOYER = '/adm/administration/employer/new';
PATHS.UPDATE_EMPLOYER = '/adm/administration/employer/:uuid';
PATHS.PERMISSIONS = '/adm/administration/permissions/list';
PATHS.UPDATE_PERMISSIONS = '/adm/administration/permissions/form/update/:uuid';

PATHS.USER_LIST = '/adm/administration/user';
PATHS.UPDATE_USER = '/adm/administration/user/:uuid';
PATHS.RECOVERY_TFA = '/recovery/TFA/:token';
PATHS.USER_TREE = '/adm/administration/user/tree/:uuid';
PATHS.BLOCK_USER = '/adm/administration/user/block/:uuid';

// INIT BOT_PROVIDER PATHS
PATHS.BOT = '/adm/administration/bot';
PATHS.BOT_LIST = '/adm/administration/bot/list';
// END BOT_PROVIDER PATHS

// INIT NOTIFICATION PATHS
PATHS.NOTIFICATION = '/adm/administration/notification';
PATHS.NOTIFICATION_LIST = '/adm/administration/notification/list';
PATHS.CREATE_NOTIFICATION = '/adm/administration/notification/form/new';
PATHS.UPDATE_NOTIFICATION = '/adm/administration/notification/form/update/:uuid';
// END NOTIFICATION PATHS

PATHS.PLATFORM_CASHFLOWS = '/adm/administration/cashFlows';
PATHS.PLATFORM_CASHFLOWS_LIST = '/adm/administration/cashFlows/list';

PATHS.MARKETING = '/adm/administration/resources';
PATHS.MARKETING_LIST = '/adm/administration/resources/list';
PATHS.CREATE_MARKETING = '/adm/administration/resources/new';
PATHS.UPDATE_MARKETING = '/adm/administration/resources/form/update/:uuid';
/* GENERATOR_INJECT_PATHS */

export default PATHS;
