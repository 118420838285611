import { Ability, AbilityBuilder } from '@casl/ability';
import { store } from '../../store';
import { ROLE_ADM } from '../../constants/index';
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}
const ability = new Ability([], { subjectName });

export const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder(Ability);

  if (auth.role === ROLE_ADM) {
    can('manage', 'all');
  }
  if (auth?.userPermissions?.permissions) {
    const permissions = auth.userPermissions.permissions;
    permissions.forEach((per) => {
      can(per);
    });
  }
  return rules;
};

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth.info;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth ? currentAuth : 'anonymous'));
  }
});

export const buildAbilityFor = (role) => {
  return ability.update(defineRulesFor(currentAuth ? currentAuth : 'anonymous'));
};
