import React, { Component } from 'react';

class Home extends Component {
  componentDidMount() {}

  render() {
    return <></>;
  }
}

Home.propTypes = {};

export default Home;
