import { store } from '../store/index';
import {
  loginUser,
  loginSucess,
  logoutUser,
  getAuthProfile,
  forgotPass,
  recoveryUserPassword,
} from '../store/actions/AuthActions';

/**
 * Login user
 * @param {*} values
 */
export const login = async (values) => {
  const objSend = {
    email: values.user,
    password: values.password,
  };
  const result = await store.dispatch(loginUser(objSend));
  if (result && result.payload.data && result.payload.data.token) {
    const auth = {
      token: result.payload.data.token,
      auth2fa: false,
    };
    await store.dispatch(loginSucess(auth));
    return auth;
  }

  return null;
};

/**
 * Get auth profile info
 */
export const getAuthUserProfile = async () => {
  const result = await store.dispatch(getAuthProfile());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Logout user of system
 */
export const logout = async () => {
  await store.dispatch(logoutUser());
};

/**
 * Send reques for password recovery
 * @param {*} values
 */
export const forgotPassword = async (values) => {
  const objSend = {
    email: values.email,
  };
  const result = await store.dispatch(forgotPass(objSend));
  if (result && result.payload && result.payload.status && result.payload.status === 'OK') {
    return 'OK';
  }

  return null;
};

/**
 * Login user
 * @param {*} values
 */
export const recoveryPassword = async (values) => {
  const objSend = {
    token: values.token,
    password: values.password,
  };
  const result = await store.dispatch(recoveryUserPassword(objSend));
  if (result && result.payload.status && result.payload.status === 'OK') {
    return 'OK';
  }

  return null;
};
