import React from 'react';
import PropTypes from 'prop-types';
import RecoveryPassword from '../../../components/user/recoveryPassword';
import Layout from '../../../components/layouts/centerLayout';

const RecoveryPasswordPage = (props) => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <RecoveryPassword token={props.token} />
        </>
      }
    />
  );
};

RecoveryPasswordPage.propTypes = {
  token: PropTypes.string,
};

export default RecoveryPasswordPage;
