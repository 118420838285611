import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import PATHS from '../../constants/paths';
import BotListPage from '../../pages/administration/bot/list';
/* GENERATOR_INJECT_IMPORT_PAGE */

class BotRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute path={PATHS.BOT_LIST} component={BotListPage} />
        {/* GENERATOR_INJECT_LIST_ROUTE */}
        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

BotRoutesContainer.propTypes = {};

export default BotRoutesContainer;
