import React, { useState } from 'react';
import List from '../cashflowsList';
import Filter from '../cashflowsFilter';
import { Title } from '@smartups-sl/react-basic-components';
import { useTranslation } from 'react-i18next';
import { WALLET_SERVICE } from '../../../../constants/index';

const MovmentsListPage = (props) => {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState(WALLET_SERVICE['BTC']);
  const [filters, setfilters] = useState(undefined);

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};

    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setfilters(newFilter);
  };

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setfilters(undefined);
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 mt-5">
          <Title type="h3" variant="primary">
            {t('forms.cashflow.title')}
          </Title>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-10 text-end">
              <Filter onSubmit={handlerOnSubmitFilter} onClean={handlerCleanFilter} />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 ">
          <List className="marg-top-1rem" filters={filters} walletService={wallet} />
        </div>
      </div>
    </>
  );
};

MovmentsListPage.propTypes = {};

export default MovmentsListPage;
