import React from 'react';
import PropTypes from 'prop-types';
import { EmployerForm } from '@smartups-sl/react-component-library';
import Layout from '../../../../components/layouts/verticalLayout';

import PATHS from '../../../../constants/paths';
import { ROLES, ROLE_ADM } from '../../../../constants/index';
import { actions } from '../../../../api/index';
import { urlBase } from '../../../../api/urls';
import { useSelector } from 'react-redux';
import { getUserRoleSelector } from '../../../../store/selectors/authSelector';

const employerPaths = {
  EMPLOYER_LIST: PATHS.EMPLOYER_LIST,
};

const EmployerFormPage = (props) => {
  const myStringRol = useSelector(getUserRoleSelector);
  const getRoles = () => {
    const myRol = ROLES.find((rol) => rol.value === myStringRol);
    if (myStringRol !== ROLE_ADM) {
      const roles = [];
      for (const rol of ROLES) {
        if (rol.level >= myRol.level) {
          roles.push(rol);
        }
      }
      return roles;
    } else {
      return ROLES;
    }
  };
  return (
    <Layout
      body={
        <>
          <EmployerForm
            uuid={props.uuid}
            roles={getRoles()}
            employerPaths={employerPaths}
            urlBase={urlBase}
            actions={actions}
          />
        </>
      }
    />
  );
};

EmployerFormPage.propTypes = {
  uuid: PropTypes.string,
};

export default EmployerFormPage;
