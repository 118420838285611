import { store } from '../store/index';
import { getNationalities, setBack } from '../store/actions/GeneralActions';

/**
 * Get all nationalities
 */
export const getAllNationalities = async () => {
  const result = await store.dispatch(getNationalities());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Set Back
 */
export const setFilterBack = async (val) => {
  store.dispatch(setBack(val));
};
