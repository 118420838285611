import styled from 'styled-components';

export const IconStyled = styled.i`
  width: 30px;
`;

export const OffCanvasHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0 !important;
  z-index: 99999 !important;
`;

export const ButtonOption = styled.button`
  border: 0px;
  background: transparent;
  color: ${({ theme }) => (theme ? theme.sidebar.colorLink : '#fff')};

  &:hover {
    color: ${({ theme }) => (theme ? theme.sidebar.colorLinkHover : '#fff')};
  }
`;

export const Logo = styled.img`
  width: 100%;
  max-width: ${({ offCanvas }) => (offCanvas ? '100px' : '120px')};
  margin-top: ${({ offCanvas }) => (offCanvas ? '-30px' : 'initial')};
`;

export const NavLink = styled.li`
  display: block;
  padding: 0.5rem 0rem;
`;
