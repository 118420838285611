import styled from 'styled-components';

export const SettingButton = styled.button`
  border: 0px;
  background: transparent;
  color: ${({ active, theme }) =>
    active ? theme.links.settings.active : theme.links.settings.default};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  padding: 10px 10px 10px 10px;
  font-size: 1.2rem;
  font-weight: 500;

  &:hover {
    color: ${({ active, theme }) =>
      active ? theme.links.settings.active : theme.links.settings.hover};
  }
`;

export const BodyContainer = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => (theme ? theme.body.backgroundColor : '#fff')};
`;

export const SideBarContainer = styled.div`
  width: 180px;
  height: 100%;
  position: fixed;
  background-color: ${({ theme }) => (theme ? theme.sidebar.backgroundColor : '#fff')};
  transition: 0.5s;
`;

export const OffCanvas = styled.div`
  top: 0;
  left: 0;
  width: 180px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => (theme ? theme.sidebar.backgroundColor : '#fff')};
  transform: translateX(-100%);
`;
