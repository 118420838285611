import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getAuthTokenSelector, getUserRoleSelector } from '../../store/selectors/authSelector';
import PATHS from '../../constants/paths';
// import { ROLE_ADM, ROLE_DEV, ROLE_SUPPORT } from '../../constants/index';
// import { ACTION, DOMAIN } from '../../components/permissions/rolesPermission';
import { AbilityContext } from '../../components/permissions/Can';

class AuthAdminRoute extends Component {
  render() {
    const { token, role, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (
            token
            // &&
            // (role === ROLE_ADM || role === ROLE_DEV || role === ROLE_SUPPORT) &&
            // this.context.can(ACTION.VIEW, DOMAIN.ADMINISTRATION)
          ) {
            return <Component {...props} />;
          } else {
            return <Redirect to={PATHS.LOGIN} />;
          }
        }}
      />
    );
  }
}

AuthAdminRoute.contextType = AbilityContext;

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  role: getUserRoleSelector(state),
});

export default connect(mapStateToProps, {})(AuthAdminRoute);
