import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import {
  InputDateFormik,
  InputSelectFormik,
  InputTextFormik,
} from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './cashflowsFilter.data.form';
import { Button } from '@smartups-sl/react-basic-components';
import { CASHFLOW_FILTER_TYPES } from '../../../../constants/index';

const CashFlowFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;
  return (
    <>
      <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
        <div className="row justify-content-end">
          <div className="col-2">
            <Field
              id="dateInit"
              className="form-control"
              name="dateInit"
              component={InputDateFormik}
              language="es"
              dateFormat="dd/MM/yyyy"
              placeholder={t('forms.cashflow.labels.dateInit')}
            />
          </div>
          <div className="col-2">
            <Field
              id="dateEnd"
              className="form-control"
              name="dateEnd"
              component={InputDateFormik}
              language="es"
              dateFormat="dd/MM/yyyy"
              defaultHours={23}
              defaultMinutes={59}
              placeholder={t('forms.cashflow.labels.dateEnd')}
            />
          </div>
          <div className="col-3">
            <Field
              id="type"
              name="type"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={CASHFLOW_FILTER_TYPES.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
            />
          </div>
          <div className="col-5 d-flex justify-content-between">
            <Field
              id="uuid"
              className="form-control"
              component={InputTextFormik}
              name="uuid"
              fieldType="text"
              placeholder={t('forms.cashflow.labels.uuidFilter')}
            />
            <div>
              <Button type="submit" className="btn btn-md me-2" variant="primary">
                <i className="fas fa-search"></i>
              </Button>
              {/* {filtered && ( */}
              <Button
                type="button"
                className="btn btn-md"
                variant="secondary"
                onClick={() => onClean(props.resetForm)}
                title={t('cleanFilter')}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

CashFlowFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const CashFlowFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'CashFlowFilterForm',
})(withTranslation()(CashFlowFilterForm));

export default CashFlowFilterFormF;
