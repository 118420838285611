import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Title, Button, Link } from '@smartups-sl/react-component-library';
import { Tree, SimpleTree } from '@smartups-sl/react-utils-components';
import UniLevelTreeNode from './components/uniLevelTreeNode';
import SimpleTreeNode from './components/simpleTreeNode';
import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
// import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { SwitchStatus, LabelStatus } from './unilevelTree.styled';

const UnilevelTree = (props) => {
  const [loading, setLoading] = useState(true);
  const { t, data, getNodes, parentNode, nodeVisited, viewType, onChangeType, onback } = props;
  // const breakDevice = checkBootstrapSizeBreak();

  let imageFinal = '';
  if (parentNode?.avatarUrl) {
    imageFinal = parentNode.avatarUrl;
  } else {
    imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/no-image.png`;
  }
  const parent = {
    userUuid: parentNode?.uuid,
    name: `${parentNode?.email || ''}`,
    email: `${parentNode?.rank || ''}`,
    image: imageFinal,
  };

  const closeLoading = () => {
    setLoading(false);
  };

  const generateData = () => {
    const nodes = [];
    if (parentNode && data) {
      const parent = {
        label: parentNode?.email,
        id: parentNode?.uuid,
        parentId: null,
        items: null,
        info: parentNode,
      };
      nodes.push(parent);
      data.forEach((node) => {
        const temp = {
          label: node.email,
          id: node.userUuid,
          parentId: node.fatherUuid,
          items: null,
          info: node,
        };
        nodes.push(temp);
      });
    }
    return nodes;
  };
  return (
    <>
      <div className="row">
        <div className="col-12 marg-top-40px">
          <Title type="h3" variant="black">
            {t('unilevel')}
          </Title>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-5 col-lg-5 marg-btm-1rem text-center">
          {/* <div>{t('simpleTree')}</div> */}
          <div className="serviceStatusContainer form-switch">
            <SwitchStatus
              statusCheck={viewType === 'rama'}
              className="serviceStatus form-check-input"
              type="checkbox"
              id="selectUnilevel"
              onChange={(event) => {
                if (event.target.checked) {
                  onChangeType('rama');
                } else {
                  onChangeType('simple');
                }
              }}
              checked={viewType === 'rama'}
            />
            <LabelStatus className="form-check-label">{t('complexTree')}</LabelStatus>
          </div>
        </div>
      </div>
      {parentNode && parentNode.referral && (
        <div className="row justify-content-center">
          <div className="col-12 marg-btm-1rem text-left">
            <Link
              to={`/adm/administration/user/tree/${parentNode.referral}`}
              type="routerDom"
              variant="primary"
            >
              {`${t('treeUpline')} `}
              <i className="fas fa-level-up-alt"></i>
            </Link>
          </div>
        </div>
      )}

      {viewType === 'simple' ? (
        <SimpleTree
          nodes={generateData()}
          icon="fas fa-chevron-right"
          onSelect={getNodes}
          isLoading={loading}
          colorLoader="#e81d75"
          heightLoader={50}
          widthLoader={50}
          closeLoading={closeLoading}
          theme="mypocketbot"
          customTheme={{
            mypocketbot: {
              text: '#fff',
              bg: '#136b88',
              highlight: '#136b88',
              decal: '#fff',
              accent: '#E03A85',
            },
          }}
        >
          <SimpleTreeNode firstLevel={parentNode?.treeLevel}></SimpleTreeNode>
        </SimpleTree>
      ) : (
        <Tree
          nameParentId="fatherUuid"
          idNode="userUuid"
          data={data || []}
          onSelectNode={getNodes}
          parentNode={parent}
          idNodeTitle="name"
          idNodeText="email"
          idImageNode="image"
          connectorColor="#136b88"
          nodeVisited={nodeVisited}
        >
          <UniLevelTreeNode firstLevel={parentNode?.treeLevel} nodeVisited={nodeVisited} />
        </Tree>
      )}
      <div className="row justify-content-center">
        <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
          <Button type="button" className="btn btn-lg" variant="secondary" onClick={() => onback()}>
            {t('forms.user.buttons.back')}
          </Button>
        </ButtonsContainer>
      </div>
    </>
  );
};

UnilevelTree.propTypes = {
  data: PropTypes.array,
  getNodes: PropTypes.func,
  parentNode: PropTypes.object,
  t: PropTypes.any,
  nodeVisited: PropTypes.array,
  onChangeType: PropTypes.func,
  viewType: PropTypes.string,
  onback: PropTypes.func,
};

export default UnilevelTree;
