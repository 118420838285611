import { handleActions } from 'redux-actions';

import { USER_INFO, CLEAN_USER } from '../../constants/index';

export const user = handleActions(
  {
    [CLEAN_USER]: () => ({}),
    [USER_INFO]: (state, action) => ({ ...state, info: action.payload }),
  },
  {},
);
