import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store';
import './utils/i18n/i18n';
import { getTheme } from './design/themes/utilTheme';
import { ThemeProvider } from 'styled-components';
import { StyledApp } from './design/styleComponents/globalComponents';

const rootComponent = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
      <ThemeProvider theme={getTheme(process.env.REACT_APP_THEME)}>
        <StyledApp>
          <App />
        </StyledApp>
      </ThemeProvider>
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);

ReactDOM.render(rootComponent, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
