import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputSelectFormik, InputTextFormik } from '@smartups-sl/react-forms-components';

import { FormFilter } from '@smartups-sl/react-complex-components';
import { dataModel, validationDataModel } from './botFilter.data.form';
import { BOT_STATUS } from '../../../../constants/index';

const BotFilterForm = (props) => {
  const { t } = useTranslation();
  const { setFilters } = props;

  return (
    <>
      <FormFilter
        dataModel={dataModel}
        validationDataModel={validationDataModel}
        title={t('forms.bot.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.bot.buttons.filter')}
        textButtonClean={t('forms.bot.buttons.clean')}
        formName="BotFilterForm"
        classNamefilterContainer="dark-filter-container"
        variantTitle="primary"
        animationArrowClassname="filter-arrow"
      >
        <div className="row">
          <div className="col-12">
            <Field
              id="status"
              labelField={t('forms.bot.labels.status')}
              name="status"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={BOT_STATUS.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
              addOptionEmpty
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Field
              id="accountId"
              className="form-control"
              labelField={t('forms.bot.labels.accountId')}
              name="accountId"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
        </div>
      </FormFilter>
    </>
  );
};

BotFilterForm.propTypes = {
  setFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default BotFilterForm;
