import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../../../components/administration/marketing/marketingForm';
import Layout from '../../../../components/layouts/verticalLayout';

const ResourcesFormPage = (props) => {
  return (
    <Layout
      body={
        <>
          <Form uuid={props.uuid} />
        </>
      }
    />
  );
};

ResourcesFormPage.propTypes = {
  uuid: PropTypes.string,
};

export default ResourcesFormPage;
