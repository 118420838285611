import * as Yup from 'yup';

export const dataModel = {
  message: '',
  priority: '',
};

export const validationDataModel = (edit) => {
  if (edit) {
    return Yup.object().shape({
      message: Yup.string()
        .required('forms.validations.required')
        .max(200, {
          message: 'forms.validations.maxLength',
          args: { max: 200 },
        }),
      priority: Yup.string().required('forms.validations.required'),
    });
  }

  return Yup.object().shape({
    message: Yup.string()
      .required('forms.validations.required')
      .max(200, {
        message: 'forms.validations.maxLength',
        args: { max: 200 },
      }),
    priority: Yup.string().required('forms.validations.required'),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
