import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import PATHS from '../../constants/paths';
import MarketingFormPage from '../../pages/administration/marketing/form';
import ListMarketingPage from '../../pages/administration/marketing/list';

class NewsRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute exact path={PATHS.MARKETING_LIST} component={ListMarketingPage} />
        <AuthAdminRoute exact path={PATHS.CREATE_MARKETING} component={MarketingFormPage} />
        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_MARKETING}
          component={(props) => <MarketingFormPage uuid={props.match.params.uuid} />}
        />
      </Switch>
    );
  }
}

NewsRoutesContainer.propTypes = {};

export default NewsRoutesContainer;
