import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InputSelectFormik, InputTextareaFormik } from '@smartups-sl/react-forms-components';

import { BLOCK_LEVELS, BLOCK_LEVEL3 } from '../../../../constants/index';
import { dataModel, validationDataModel, setInitialValues } from './userBlockForm.data.form';

import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-basic-components';

const UserBlockForm = (props) => {
  const { t, handleSubmit, onback } = props;
  const [mandatoryMessage, setMandatoryMessage] = useState(false);

  const handlerOnchangeBlockLevel = (blockLevel) => {
    if (blockLevel === BLOCK_LEVEL3.code) {
      setMandatoryMessage(true);
    } else {
      setMandatoryMessage(false);
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="row marg-btm-1rem marg-top-40px">
            <div className="col-12">
              <Title type="h3" variant="primary">
                {t('forms.user.titleBlock')}
              </Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="blockedLevel"
                  labelField={t('forms.user.labels.levelblock')}
                  name="blockedLevel"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={BLOCK_LEVELS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  onChangeValue={handlerOnchangeBlockLevel}
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="message"
                  className="form-control-textarea"
                  labelField={t('forms.user.labels.message')}
                  name="message"
                  placeholder=""
                  component={InputTextareaFormik}
                  rows={5}
                  readOnly={!mandatoryMessage}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.user.buttons.back')}
                </Button>
                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.user.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

UserBlockForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
  nationalities: PropTypes.array,
};

const UserBlockFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'UserBlockForm',
})(withTranslation()(UserBlockForm));

export default UserBlockFormF;
