import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import PATHS from '../../constants/paths';
import EmployerFormPage from '../../pages/administration/employer/form';
import ListEmployerPage from '../../pages/administration/employer/list';

class EmployerRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute exact path={PATHS.EMPLOYER_LIST} component={ListEmployerPage} />
        <AuthAdminRoute exact path={PATHS.CREATE_EMPLOYER} component={EmployerFormPage} />
        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_EMPLOYER}
          component={(props) => <EmployerFormPage uuid={props.match.params.uuid} />}
        />
      </Switch>
    );
  }
}

EmployerRoutesContainer.propTypes = {};

export default EmployerRoutesContainer;
