import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 50px;
  background: ${(props) => (props.type === 'login' ? 'none' : props.theme.header.backgroundColor)};
`;

export const Logo = styled.img`
  height: 44px;
`;

export const LogoMobile = styled.img`
  height: 45px;
`;

export const SidebarButtonContainer = styled.div`
  display: flex;
  outline: 0px auto !important;
  color: #fff;
`;

export const SidebarButton = styled.button`
  outline: 0px auto !important;
  color: #fff;
  font-size: 1.5rem;
  padding: 0px 5px 0px 15px;
  background: transparent;
  border: 0px;
  margin-top: 2px;
`;
