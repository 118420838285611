import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputTextFormik } from '@smartups-sl/react-forms-components';

import { FormFilter } from '@smartups-sl/react-complex-components';
import { dataModel, validationDataModel } from './notificationFilter.data.form';

const NotificationFilterForm = (props) => {
  const { t } = useTranslation();
  const { setFilters } = props;

  return (
    <>
      <FormFilter
        dataModel={dataModel}
        validationDataModel={validationDataModel}
        title={t('forms.notification.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.notification.buttons.filter')}
        textButtonClean={t('forms.notification.buttons.clean')}
        formName="NotificationFilterForm"
        classNamefilterContainer="dark-filter-container"
        variantTitle="primary"
        animationArrowClassname="filter-arrow"
      >
        <div className="row">
          <div className="col-12">
            <Field
              id="message"
              className="form-control"
              component={InputTextFormik}
              labelField={t('forms.notification.labels.message')}
              name="message"
              fieldType="text"
              placeholder=""
              mandatory
            />
          </div>
        </div>
      </FormFilter>
    </>
  );
};

NotificationFilterForm.propTypes = {
  setFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default NotificationFilterForm;
