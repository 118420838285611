import { store } from '../store/index';
import {
  verifyTfa,
  getUnilevelTree,
  getUserByUuid,
  updateBlockUser,
  createResource,
  getResourceByUuid,
  updateResource,
  deleteResource,
  filterMarketing,
} from '../store/actions/AdminActions';

/**
 * Get user by uuid
 */
export const getUser = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getUserByUuid(param));
  if (result?.payload?.data) {
    const dataF = result.payload.data;
    dataF.treeLevel = result.payload.treeLevel || null;
    return result.payload.data;
  }
  return null;
};

/**
 * Verify 2FA validate token
 */
export const verifyTfaToken = async (obj, token) => {
  const param = [token];
  const result = await store.dispatch(verifyTfa(obj, param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get unilevel tree nodes
 * @param {*} userId
 */
export const getUnilevelTreeNodes = async (userId) => {
  const objSend = [userId];
  const result = await store.dispatch(getUnilevelTree(objSend));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Block User
 */
export const blockUser = async (values, uuid) => {
  const objSend = {
    blockedLevel: values.blockedLevel && values.blockedLevel !== '-1' ? values.blockedLevel : null,
  };
  if (values.message) {
    objSend.message = values.message;
  }
  const result = await store.dispatch(updateBlockUser(objSend, [uuid]));
  if (result?.payload?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Create Resource
 */
export const addResource = async (values) => {
  const objSend = {
    name: values.name,
    enabled: values.enabled,
    doc: values.doc,
    docFileName: values.docFileName,
    lang: values.lang,
    category: values.category,
  };
  const result = await store.dispatch(createResource(objSend));
  if (result?.payload?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Get Resource
 */
export const getResource = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getResourceByUuid(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update Resource
 */
export const updateDataResource = async (values) => {
  const objSend = {
    name: values.name,
    enabled: values.enabled,
    lang: values.lang,
    category: values.category,
  };
  const param = [values.uuid];
  if (values.doc && values.doc.length > 0) {
    objSend.doc = values.doc;
    objSend.docFileName = values.docFileName;
  }
  const result = await store.dispatch(updateResource(objSend, param));
  if (result?.payload?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Delete Resource
 */
export const delResource = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(deleteResource(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List Resources
 */
export const listFilterResources = async (filter) => {
  const result = await store.dispatch(filterMarketing(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
