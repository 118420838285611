import React from 'react';
import FilterList from '../../../../components/administration/bot/botFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const BotListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

BotListPage.propTypes = {};

export default BotListPage;
