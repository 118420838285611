import { createAction } from 'redux-actions';
import { apiPut, apiGetFile, apiGet } from '../../api/index';
import {
  urlauthProfile,
  urlAvatar,
  urlGetAvatar,
  urlFilterUser,
  urlGetClientToken,
  urlauthChangePass,
  urlActiveUser,
} from '../../api/urls';
import {
  UPDATE_PROFILE,
  UPDATE_AVATAR,
  GET_AVATAR,
  FILTER_USERS,
  PAGE_SIZE_10,
  GET_CLIENT_TOKEN,
  ACTIVE_USER,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.email) filter += '&email=' + filters.email;
    if (filters.status) filter += '&status=' + filters.status;
  }

  return filter;
};

/**
 * Get auth info profile (user loged).
 */
export const updateProfile = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlauthProfile, obj, undefined, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const updatePassword = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlauthChangePass, obj, undefined, true)()
);

/**
 * Update User Avatar.
 */
export const updateUserAvatar = createAction(UPDATE_AVATAR, (obj) =>
  apiPut(urlAvatar, obj, undefined, true)()
);

/**
 * Get User Avatar.
 */
export const getAvatar = createAction(GET_AVATAR, (param) =>
  apiGetFile(urlGetAvatar, param, true)()
);

/**
 * Filter list users.
 */
export const filterUsers = createAction(FILTER_USERS, (filter, param) =>
  apiGet(urlFilterUser + generateFilterQuery(filter), param, true)()
);

/**
 * Get Client token.
 */
export const getClientToken = createAction(GET_CLIENT_TOKEN, (param) =>
  apiGet(urlGetClientToken, param, true)()
);

/**
 * Update User Avatar.
 */
export const activeUser = createAction(ACTIVE_USER, (obj) =>
  apiPut(urlActiveUser, {}, obj, true)()
);
