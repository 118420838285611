import { createAction } from 'redux-actions';
import { apiPost, apiGet } from '../../api/index';
import { urllogin, urlauthProfile, urlForgotPassword, urlRecoveryPass } from '../../api/urls';
import {
  LOGIN_SUCCESS,
  CLEAN_AUTH,
  UPDATE_TOKEN,
  LOGIN_USER,
  SET_AUTH_PROFILE,
  FORGOT_PASS,
  RECOVERY_PASS,
} from '../../constants/index';

/**
 * Login to server. Call to server.
 */
export const loginUser = createAction(LOGIN_USER, (obj) =>
  apiPost(urllogin, obj, undefined, false)(),
);

/**
 * Get auth info profile (user loged).
 */
export const getAuthProfile = createAction(SET_AUTH_PROFILE, () =>
  apiGet(urlauthProfile, undefined, true)(),
);

/**
 * Login to server. Call to server.
 */
export const forgotPass = createAction(FORGOT_PASS, (obj) =>
  apiPost(urlForgotPassword, obj, undefined, false)(),
);

/**
 * Recovery user password.
 */
export const recoveryUserPassword = createAction(RECOVERY_PASS, (obj) =>
  apiPost(urlRecoveryPass, obj, undefined, false)(),
);

export const loginSucess = createAction(LOGIN_SUCCESS, (data) => data);

export const logoutUser = createAction(CLEAN_AUTH, (data) => data);

export const updateToken = createAction(UPDATE_TOKEN, (data) => data);
