import * as Yup from 'yup';

export const dataModel = {
  status: '',
  accountId: '',
};

export const validationDataModel = Yup.object().shape({
  status: Yup.string().optional(''),
  accountId: Yup.string().optional(''),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
