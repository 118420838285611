import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel } from './recoveryPassword.data.form';
import { Button, Title } from '@smartups-sl/react-component-library';
import {
  CenterContainer,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';

const RecoveryForm = (props) => {
  const { t, handleSubmit } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-5">
        <Title type="h1" className="text-center" variant="black">
          {t('forms.recovery.title')}
        </Title>

        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <Field
                id="password"
                className="form-control"
                labelField={t('forms.recovery.labels.newpassword')}
                name="password"
                component={InputTextFormik}
                fieldType="password"
                placeholder=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="passwordConfirm"
                className="form-control"
                labelField={t('forms.recovery.labels.passwordConfirm')}
                name="passwordConfirm"
                component={InputTextFormik}
                fieldType="password"
                placeholder=""
              />
            </div>
          </div>
          <ButtonsContainer className="text-center">
            <Button type="submit" className="btn btn-lg btn-block" variant="primary">
              {t('forms.recovery.buttons.recovery')}
            </Button>
          </ButtonsContainer>
        </form>
      </div>
    </CenterContainer>
  );
};

RecoveryForm.propTypes = {
  handleSubmit: PropTypes.func,
};

const RecoveryFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'RecoveryForm',
})(withTranslation()(RecoveryForm));

export default RecoveryFormF;
