import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';
import { urlGetNationalities } from '../../api/urls';
import { GET_NATIONALITIES, SET_FILTER, CLEAN_FILTERS, SET_BACK } from '../../constants/index';

/**
 * Get nationalities.
 */
export const getNationalities = createAction(GET_NATIONALITIES, () =>
  apiGet(urlGetNationalities, undefined, false)(),
);

/**
 * Set filters.
 */
export const setFilters = createAction(SET_FILTER, (data) => data);

/**
 * Clean filters.
 */
export const cleanFilters = createAction(CLEAN_FILTERS, (data) => data);

/**
 * Set Back.
 */
export const setBack = createAction(SET_BACK, (data) => data);
