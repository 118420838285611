import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as NotificationServices from '../../../../services/NotificationService';
import { withTranslation } from 'react-i18next';
import { PAGE_SIZE_10 } from '../../../../constants/index';
import { showDialog } from '@smartups-sl/react-utils-components';
import PATHS from '../../../../constants/paths';
import { LinkActionStyled } from './notificationList.styled';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { PaginateList } from '@smartups-sl/react-complex-components';

class NotificationListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    // const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.notification.table.heads.date'),
        accessor: 'createdAt',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.notification.table.heads.message'),
        accessor: 'message',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.notification.table.heads.priority'),
        accessor: 'priority',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 150,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    return (
      <>
        <div className="text-center">
          <Can do={USER_PERMISSIONS.NOTIFICATION_UPDATE}>
            <LinkActionStyled
              to={`/adm/administration/notification/form/update/${obj.original.uuid}`}
              type="routerDom"
              variant="terciary"
            >
              <i className="fas fa-edit" title="Editar" />
            </LinkActionStyled>
          </Can>
          <Can do={USER_PERMISSIONS.NOTIFICATION_DELETE}>
            <LinkActionStyled
              variant="terciary"
              onClick={() => this.handlerRemove(obj.original.uuid)}
            >
              <i className="far fa-trash-alt" title="Eliminar" />
            </LinkActionStyled>
          </Can>
        </div>
      </>
    );
  };

  handlerRemove = (value) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await NotificationServices.deleteNotification(value);
      if (result && result.deleted === true) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.notification.dialog.remove.title'),
      t('forms.notification.dialog.remove.text'),
      'warning',
      true,
      t('forms.notification.dialog.remove.buttonOk'),
      false,
      okAction,
      t('forms.notification.dialog.remove.buttonNo')
    );
  };

  toList = async (filter) => {
    const result = await NotificationServices.listFilter(filter);
    return result;
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.notification.titleList')}
          formlinkToAdd={
            this.context.can(USER_PERMISSIONS.NOTIFICATION_CREATE)
              ? PATHS.CREATE_NOTIFICATION
              : undefined
          }
          defaultPageSize={PAGE_SIZE_10}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          classNameLinkAdd="actionAdd"
          iconAdd="fas fa-plus"
          variantTitle="primary"
          floatButton
          variantIcon="primary"
          classNameTable="payper-table"
        ></PaginateList>
      </div>
    );
  }
}

NotificationListContainer.contextType = AbilityContext;

NotificationListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
};

export default withTranslation()(NotificationListContainer);
