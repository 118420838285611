/* eslint-disable no-mixed-operators */
const translations = {
  ADA: 'ADA',
  ATOM: 'ATOM',
  BCH: 'BCH',
  BSV: 'BSV',
  DASH: 'DASH',
  EOS: 'EOS',
  GNO: 'GNO',
  KFE: 'FEEE',
  QTUM: 'QTUM',
  USDT: 'USDT',
  DAO: 'XDAO',
  ETC: 'XETC',
  ETH: 'XETH',
  ICN: 'XICN',
  LTC: 'XLTC',
  MLN: 'XMLN',
  NMC: 'XNMC',
  REP: 'XREP',
  XTZ: 'XTZ',
  BTC: 'XXBT',
  XDG: 'XXDG',
  XLM: 'XXLM',
  XMR: 'XXMR',
  XRP: 'XXRP',
  XVN: 'XXVN',
  ZEC: 'XZEC',
  CAD: 'ZCAD',
  EUR: 'ZEUR',
  GBP: 'ZGBP',
  JPY: 'ZJPY',
  KRW: 'ZKRW',
  USD: 'ZUSD',
};

const CRYPTO_ATTR = {
  BTC: {
    decimals: 8,
  },
};

/**
 * Delete Zero
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const deleteZero = (n) => (parseInt(n.toString().split('.')[1]) === 0 ? parseInt(n) : n);

/**
 * Number is float
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const isFloat = (n) => n % 1 !== 0;

/**
 * Get satoshi amount from cryptocurrency
 *
 * @param  {String} crypto
 * @param  {String} value
 * @return {Promise}
 */
export const crypto2Satoshi = (crypto, value) => {
  if (CRYPTO_ATTR[crypto]) {
    const satoshi = isFloat(value * 10 ** CRYPTO_ATTR[crypto].decimals)
      ? deleteZero(
          (value * 10 ** CRYPTO_ATTR[crypto].decimals).toFixed(CRYPTO_ATTR[crypto].decimals)
        )
      : value * 10 ** CRYPTO_ATTR[crypto].decimals;

    return satoshi;
  }
  throw new Error('Invalid cryptocurrency');
};

/**
 * Get cryptocurrency value from satoshi amount
 *
 * @param  {String} crypto
 * @param  {String} satoshi
 * @return {Promise}
 */
export const satoshi2Crypto = (crypto, satoshi) => {
  if (CRYPTO_ATTR[crypto]) {
    const btc = isFloat(satoshi / 10 ** CRYPTO_ATTR[crypto].decimals)
      ? deleteZero(
          (satoshi / 10 ** CRYPTO_ATTR[crypto].decimals).toFixed(CRYPTO_ATTR[crypto].decimals)
        )
      : satoshi / 10 ** CRYPTO_ATTR[crypto].decimals;

    return btc;
  }
  throw new Error('Invalid cryptocurrency');
};
