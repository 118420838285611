import React from 'react';
import FilterList from '../../../../components/administration/notification/notificationFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const NotificationListPage = (props) => {
  return <Layout body={<FilterList />} />;
};

NotificationListPage.propTypes = {};

export default NotificationListPage;
