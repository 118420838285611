import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import MyProfileForm from './myProfile.view';
import PATHS from '../../../constants/paths';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import { getUserPersonalInfoSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';

class MyProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customErrors: undefined,
      nationalities: undefined,
      resendDisabled: true,
      from: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.from) {
      this.setState({ from: query.from });
    }
  }

  handleOnback = () => {
    this.props.history.push(PATHS.ADMIN_HOME);
  };

  handleSubmit = async (values) => {
    try {
      if (values.avatar && !values.avatar.uuid && values.avatar.length > 0) {
        await UserService.updateAvatar(values.avatar.replace('data:image/jpeg;base64,', ''));
      }
      const result = await UserService.updateMyProfile(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        title = this.props.t('forms.myProfile.messages.update.title');
        text = this.props.t('forms.myProfile.messages.update.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.myProfile.messages.update.error');
        icon = 'error';
      }

      let okAction = undefined;
      if (this.state.from) {
        okAction = () => {
          this.props.history.push(this.state.from);
        };
      }
      showDialog(title, text, icon, false, 'OK', false, okAction);
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleGetImageAvatar = async (idImage) => {
    const result = await UserService.getUserAvatar(idImage);
    return result;
  };

  render() {
    return (
      <MyProfileForm
        nationalities={this.state.nationalities || []}
        initialValues={this.props.info}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
        getAvatar={this.handleGetImageAvatar}
      />
    );
  }
}

MyProfileContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getUserPersonalInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(MyProfileContainer)));
