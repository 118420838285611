import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import {
  InputTextFormik,
  InputCheckFormik,
  InputFileFormik,
  InputSelectFormik,
} from '@smartups-sl/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './marketingForm.data.form';

import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-basic-components';
import { LANGUAGES, DOCUMENTS_CATEGORIES } from '../../../../constants/index';

const MarketingForm = (props) => {
  const { t, handleSubmit, onback, edit } = props;
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 marg-btm-1rem marg-top-40px">
          <div className="row marg-btm-1rem marg-top-40px">
            <div className="col-12">
              <Title type="h3" variant="primary">
                {edit ? t('forms.marketing.titleEdit') : t('forms.marketing.titleNew')}
              </Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="name"
                  className="form-control"
                  labelField={t('forms.marketing.labels.name')}
                  name="name"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="doc"
                  className="form-control"
                  labelField={t('forms.marketing.labels.doc')}
                  name="doc"
                  component={InputFileFormik}
                  fieldType="text"
                  placeholder=""
                  maxSize={20971520}
                  acceptedFiles=".jpeg,.png,.pdf"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Field
                  id="category"
                  labelField={t('forms.marketing.labels.category')}
                  name="category"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={DOCUMENTS_CATEGORIES.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                />
              </div>
              <div className="col-6">
                <Field
                  id="lang"
                  labelField={t('forms.marketing.labels.lang')}
                  name="lang"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={LANGUAGES.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 marg-top-1rem">
                <Field
                  id="enabled"
                  labelField={t('forms.marketing.labels.enabled')}
                  name="enabled"
                  component={InputCheckFormik}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.marketing.buttons.back')}
                </Button>
                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.marketing.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MarketingForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
};

const MarketingFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'MarketingForm',
})(withTranslation()(MarketingForm));

export default MarketingFormF;
