import * as Yup from 'yup';

export const dataModel = {
  name: '',
  lang: '',
  category: '',
};

export const validationDataModel = Yup.object().shape({
  name: Yup.string().optional('forms.validations.required'),
  lang: Yup.string().optional('forms.validations.required'),
  category: Yup.string().optional('forms.validations.required'),
});
