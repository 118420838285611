import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import RolesPermisionsPage from '../../pages/administration/rolesPermissions/list';
import FormRolesPermisionsPage from '../../pages/administration/rolesPermissions/form';
import UserListPage from '../../pages/administration/user/list';
import PATHS from '../../constants/paths';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import UserTreePage from '../../pages/administration/user/tree';
import BotRoutes from './BotRoutes';
import NotificationRoutes from './NotificationRoutes';
import EmployerRoutes from './EmployerRoutes';
import UserBlockFormPage from '../../pages/administration/user/formBlock';
import CashFlowsRoutes from './CashFlowsRoutes';
import MarketingRoutes from './MarketingRoutes';

class RoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute exact path={PATHS.USER_LIST} component={UserListPage} />
        <AuthAdminRoute
          exact
          path={PATHS.BLOCK_USER}
          component={(props) => <UserBlockFormPage uuid={props.match.params.uuid} />}
        />
        <AuthAdminRoute
          exact
          path={PATHS.USER_TREE}
          component={(props) => <UserTreePage uuid={props.match.params.uuid} />}
        />

        <AuthAdminRoute exact path={PATHS.PERMISSIONS} component={RolesPermisionsPage} />

        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_PERMISSIONS}
          component={(props) => <FormRolesPermisionsPage uuid={props.match.params.uuid} />}
        />

        <AuthAdminRoute path={PATHS.MARKETING} component={MarketingRoutes} />
        <AuthAdminRoute path={PATHS.EMPLOYER} component={EmployerRoutes} />
        <AuthAdminRoute path={PATHS.BOT} component={BotRoutes} />
        <AuthAdminRoute path={PATHS.NOTIFICATION} component={NotificationRoutes} />
        <AuthAdminRoute path={PATHS.PLATFORM_CASHFLOWS} component={CashFlowsRoutes} />

        <AuthAdminRoute path={PATHS.ADMIN} render={() => <Redirect to={PATHS.ADMINISTRATIONS} />} />
      </Switch>
    );
  }
}

RoutesContainer.propTypes = {};

export default RoutesContainer;
