/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import LoginForm from './login.view';
import * as AuthService from '../../../services/AuthService';
import PATHS from '../../../constants/paths';
import { getAuthTokenSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClear = this.handleClear.bind(this);
    this.handleClear();
  }

  handleClear = () => {
    try {
      if (this.props.token) {
        AuthService.logout();
      }
    } catch (error) {
      this.props.history.push(PATHS.ERROR);
    }
  };

  handleSubmit = async (values) => {
    try {
      const result = await AuthService.login(values);
      if (result && result.token) {
        await AuthService.getAuthUserProfile();
        this.props.history.push(PATHS.ADMIN_HOME);
        // if (resultInfo.role === ROLE_ADM || resultInfo.role === ROLE_EMPLOYEE) {
        //   this.props.history.push(PATHS.ADMIN);
        // } else {
        //   const text = this.props.t('forms.login.errors.login-incorrect');
        //   showDialog('Error', text, 'error');
        //   AuthService.logout();
        // }
      } else {
        const text = this.props.t('forms.login.errors.no-admin');
        showDialog('Error', text, 'error');
      }
    } catch (error) {
      console.log('Error');
    }
  };

  render() {
    return <LoginForm onSubmit={this.handleSubmit} />;
  }
}

LoginContainer.propTypes = {};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(LoginContainer)));
