import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../commons/header';
import Sidebar from '../commons/sidebar';
import { checkBootstrapTypeBreak } from '../../../utils/device/checkDevice';
import { OffCanvas, SideBarContainer, BodyContainer, SettingButton } from './styled';
import { Title } from '@smartups-sl/react-component-library';
import PATHS from '../../../constants/paths';

const SimpleLayout = ({ ...prop }) => {
  const breakDevice = checkBootstrapTypeBreak();
  const [toggle, setToggle] = useState(breakDevice === 'xs' || breakDevice === 'sm' ? false : true);

  const { t } = useTranslation();
  const history = useHistory();
  const hideShowSidebar = () => {
    setToggle(!toggle);
  };

  const goTo = (key) => {
    switch (key) {
      case 'my_profile':
        history.push(PATHS.MY_PROFILE);
        break;
      case 'security':
        history.push(PATHS.SECURITY);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <OffCanvas
        className="offcanvas offcanvas-start d-flex justify-content-between flex-wrap flex-column"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <Sidebar offCanvas />
      </OffCanvas>

      <SideBarContainer
        className={`side-navbar ${
          toggle ? 'active-nav' : ''
        }  d-flex justify-content-between flex-wrap flex-column`}
        id="sidebar"
      >
        <Sidebar />
      </SideBarContainer>

      <div className={`my-container  ${toggle ? 'active-cont' : ''}`}>
        <BodyContainer className="container-fluid">
          <Header
            showUserMenu={prop.showUserMenu !== undefined ? prop.showUserMenu : undefined}
            withSidebar={true}
            toggleFunc={() => {
              hideShowSidebar();
            }}
          />
          <div id="page-content-wrapper">
            <div id="page-content">
              <div className="row">
                <div className="col-12 px-5 mt-5 mb-5">
                  <Title type="h1" variant="white">
                    {t('settings')}
                  </Title>
                </div>
              </div>
              <div className="row">
                <div className="col-3 col-lg-2">
                  <ul className="nav flex-column">
                    <li className="nav-item px-4 mb-3">
                      <SettingButton
                        active={prop.active && prop.active === 'profile'}
                        type="button"
                        onClick={() => goTo('my_profile')}
                      >
                        {t('my_profile')}
                      </SettingButton>
                    </li>
                    <li className="nav-item px-4 mb-3">
                      <SettingButton
                        active={prop.active && prop.active === 'security'}
                        type="button"
                        onClick={() => goTo('security')}
                      >
                        {t('security')}
                      </SettingButton>
                    </li>
                  </ul>
                </div>
                <div className="col-9 col-lg-10">{prop.body}</div>
              </div>
            </div>
          </div>
        </BodyContainer>
      </div>
    </>
  );
};

export default SimpleLayout;
