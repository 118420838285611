import _ from 'lodash';
import { axiosInstanceNormal } from './axios';

function urlWithParams(url, params) {
  if (params) {
    const urlParams = url.match(/{param}/g) || [];
    if (params instanceof Array) {
      params.forEach((value, index) => {
        if (index < _.size(urlParams)) {
          url = _.replace(url, '{param}', value);
        } else {
          url = `${url}/${value}`;
        }
      });
    } else {
      if (_.size(urlParams)) {
        url = _.replace(url, '{param}', params);
      } else {
        url = `${url}/${params}`;
      }
    }
  }
  return url;
}

/**
 * Call api post request.
 * @param {*} url
 * @param {*} obj
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPost =
  (url, obj, params, sendProcessToken = true) =>
  () => {
    return axiosInstanceNormal({
      method: 'post',
      url: urlWithParams(url, params),
      data: obj,
      headers: sendProcessToken
        ? {
            'Content-type': 'application/json',
            Authorization: sendProcessToken,
          }
        : { 'Content-type': 'application/json' },
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        throw errorResponse.response;
      });
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGet =
  (url, params, sendProcessToken = true) =>
  () => {
    try {
      return axiosInstanceNormal({
        method: 'get',
        url: urlWithParams(url, params),
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
            }
          : { 'Content-type': 'application/json' },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGetFilterParam =
  (url, params, sendProcessToken = true, filter) =>
  () => {
    const urlFinal = urlWithParams(url, params) + filter;
    try {
      return axiosInstanceNormal({
        method: 'get',
        url: urlFinal,
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
            }
          : { 'Content-type': 'application/json' },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGetFile =
  (url, params, sendProcessToken = true) =>
  () => {
    try {
      return axiosInstanceNormal({
        method: 'get',
        url: urlWithParams(url, params),
        responseType: 'blob',
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
            }
          : { 'Content-type': 'application/json' },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api post request.
 * @param {*} url
 * @param {*} obj
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPut =
  (url, obj, params, sendProcessToken = true) =>
  () => {
    return axiosInstanceNormal({
      method: 'put',
      url: urlWithParams(url, params),
      data: obj,
      headers: sendProcessToken
        ? {
            'Content-type': 'application/json',
            Authorization: sendProcessToken,
          }
        : { 'Content-type': 'application/json' },
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        throw errorResponse.response;
      });
  };

export const apiPutFile = (url, obj, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'PUT',
    body: obj,
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then((v) => {
      if (v.status === 401) {
        let error = {
          error: {
            code: 401,
            message: 'Unhautorized',
          },
        };
        return error;
      } else {
        return v.json();
      }
    })
    .then((r) => {
      if (r.error) {
        return Promise.reject(r.error);
      }
      return (r = r.data);
    });
};

export const apiPostFile = (url, obj, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'POST',
    body: obj,
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          languageid: localStorage.getItem('preferedLanguageID'),
        })
      : new Headers({
          'Content-type': 'application/json',
          languageid: localStorage.getItem('preferedLanguageID'),
        }),
  })
    .then((v) => {
      if (v.status === 401) {
        let error = {
          error: {
            code: 401,
            message: 'Unhautorized',
          },
        };
        return error;
      } else {
        return v.json();
      }
    })
    .then((r) => {
      if (r.error) {
        return Promise.reject(r.error);
      }
      return (r = r.data);
    });
};

export const apiDelete =
  (url, params, sendProcessToken = true) =>
  () => {
    try {
      return axiosInstanceNormal({
        method: 'delete',
        url: urlWithParams(url, params),
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
            }
          : { 'Content-type': 'application/json' },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

export const actions = {
  apiGet: apiGet,
  apiPost: apiPost,
  apiPut: apiPut,
  apiDelete: apiDelete,
};
