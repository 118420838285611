import styled from 'styled-components';
import { Link } from '@smartups-sl/react-component-library';

export const LinkActionStyled = styled(Link)`
  margin-right: 5px;
  border: 1px solid #ff4363;
  padding: 3px;
  border-radius: 5px;

  &:hover {
    background: #ff4363;
    color: #fff;
  }
`;

export const ErrorIcon = styled.i`
  color: #ff4363;
  margin-right: 5px;
  font-size: 0.875rem;
`;

export const LinkAction2Styled = styled.a`
  margin-right: 5px;
  color: #fff;
  font-size: 0.875rem;

  &:hover {
    color: #fff;
    font-size: 0.875rem;
  }
`;

export const StrategyCode = styled.span`
  color: #ff4363;
  cursor: pointer;
`;
