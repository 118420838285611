import { createSelector } from 'reselect';

const getStateAuthSelector = (state) => state.auth;

export const getAuthStatusSelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.status) {
    return auth.status;
  }

  return undefined;
});

export const getAuthTokenSelector = createSelector(getAuthStatusSelector, (status) => {
  if (status && status.token) {
    return status.token;
  }

  return undefined;
});

export const getAuthInfoSelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.info !== undefined) {
    return auth.info;
  }

  return undefined;
});

export const getAuthPemKeySelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.pemKey !== undefined) {
    return auth.pemKey;
  }

  return undefined;
});

export const getUserRoleSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.role) {
    return info.role;
  }
  return undefined;
});

export const getUserPersonalInfoSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const data = {
      name: info.name,
      surname: info.surname,
      nationality: info.nationality,
      role: info.role,
      email: info.email,
    };
    if (info.avatar) {
      data.avatar = info.avatar;
    }
    return data;
  }

  return undefined;
});
