import { createAction } from 'redux-actions';
import { apiGet, apiPut } from '../../api/index';

import { urlBotSecured, urlBotErrorSecured, urlBotSupportSecured } from '../../api/urls';

import { PAGE_SIZE_10, FILTER_BOT, CONFIRM_BOT, CONFIG_SUPPORT } from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.status) filter += '&status=' + filters.status;
    if (filters.accountId) filter += '&accountId=' + filters.accountId;
  }

  return filter;
};

/**
 * Filter list Bot.
 */
export const filterBot = createAction(FILTER_BOT, (filter, param) =>
  apiGet(urlBotSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Confirm Bot.
 */
export const confirmBotRunning = createAction(CONFIRM_BOT, (param) =>
  apiPut(urlBotSecured, undefined, param, true)()
);

/**
 * Confirm Bot.
 */
export const pastToSupportConfig = createAction(CONFIG_SUPPORT, (param) =>
  apiPut(urlBotSupportSecured, undefined, param, true)()
);

/**
 * Update bot.
 */
export const updateBot = createAction('UPDATE_BOT', (obj, param) =>
  apiPut(urlBotErrorSecured, obj, param, true)()
);
