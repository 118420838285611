import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { dataModel, validationDataModel } from './errorBotForm.data.form';
import { CenterContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-basic-components';
import { InputSelectFormik } from '@smartups-sl/react-forms-components';
import { BOT_ERROR } from '../../../../constants/index';
const ErrorForm = (props) => {
  const { t, handleSubmit, cancelAction } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center mt-5 mb-4">
      <div className="col-10">
        <Title type="h3" className="text-center" variant="primary">
          {t('forms.bot.errorTitle')}
        </Title>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 login-padding-sup">
              <Field
                id="error"
                name="error"
                labelField={t('forms.bot.labels.error')}
                component={InputSelectFormik}
                optionLabel="label"
                optionValue="code"
                options={BOT_ERROR.map((item) => ({
                  ...item,
                  label: t(item.label),
                }))}
                classNamePrefix="dark"
                placeholder=""
              />
            </div>
          </div>
          <div className="row justify-content-center mt-5 mb-4">
            <div className="col-12 col-md-6 text-center">
              <Button
                type="button"
                className="btn btn-lg btn-block"
                variant="secondary"
                onClick={() => cancelAction()}
              >
                {t('forms.bot.buttons.cancel')}
              </Button>
            </div>
            <div className="col-12 col-md-6 text-center">
              <Button type="submit" className="btn btn-lg btn-block" variant="primary">
                {t('forms.bot.buttons.save')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </CenterContainer>
  );
};

ErrorForm.propTypes = {
  handleSubmit: PropTypes.func,
  cancelAction: PropTypes.func,
};

const ErrorFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'ErrorForm',
})(withTranslation()(ErrorForm));

export default ErrorFormF;
