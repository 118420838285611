import React from 'react';
import Home from '../../../components/user/home';
import Layout from '../../../components/layouts/verticalLayout';

const HomePage = () => {
  return (
    <Layout
      body={
        <>
          <Home />
        </>
      }
    />
  );
};

HomePage.propTypes = {};

export default HomePage;
