import styled from 'styled-components';

export const Rank = styled.span`
  color: #fff;
  padding: 10px 5px;
  background: ${({ rankColor }) => (rankColor ? rankColor : 'transaparent')};
`;

export const Row = styled.div`
  margin-right: 0px;
  margin-left: 0px;
`;

export const ColNopadding = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NodeContainer = styled.div`
  background: #fff;
  padding: 7px 7px;
  border: 2px solid #136b88;
  border-radius: 15px;
`;

export const Arrow = styled.img`
  width: 30% !important;
  height: 5px !important;
  float: inherit !important;
  margin-top: -10px;
`;

export const ColArrow = styled.div`
  height: 8px !important;
  cursor: pointer;
`;
