const colors = {
  primary: '#136b88',
  primaryLight: '#1982a5',
  secondary: '#363f5a',
  fourthy: '#ff4363',
  terciary: '#ff4363',
  terciaryLight: '#ff4363',
  white: '#ffffff',
  red: '#ff0000',
};

const theme = {
  iconLink: {
    border: colors.fourthy,
    background: 'transparent',
    color: colors.fourthy,
    borderHover: colors.fourthy,
    backgroundHover: colors.fourthy,
    colorHover: colors.white,
  },
  platforms: {
    smartnet: colors.primary,
    mypocketbot: colors.primary,
  },
  botType: {
    boty: '#f7833f',
    ibot: '#ff6882',
    mybot: '#ed3335',
  },
  colors: {
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    darkPrimary: '#00736c',
    terciary: colors.terciary,
    fourthy: colors.fourthy,
    secondary: colors.secondary,
    white: '#ffffff',
    warrning: '#e3e600',
    yellow: '#e3e600',
    alert: '#721c24',
    negative: colors.red,
  },
  paginations: {
    buttons: {
      disabled: '#fff',
      color: '#fff',
      hover: '#ff4363',
      align: 'center',
    },
  },
  notifications: {
    personal: {
      color: colors.secondary,
      colorHover: colors.white,
      backgroundColor: colors.white,
      backgroundColorHover: colors.terciary,
      badge: colors.fourthy,
    },
    general: {
      colorHover: colors.white,
      color: colors.secondary,
      backgroundColor: colors.white,
      backgroundColorHover: colors.terciary,
      badge: colors.fourthy,
    },
  },
  sidebar: {
    colorLink: '#ffffff',
    colorLinkHover: '#ffffff',
    backgroundColor: '#363f5a',
  },
  body: {
    backgroundColor: '#fdfdfd',
  },
  header: {
    backgroundColor: '#363f5a',
  },
  buttons: {
    default: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: '#000',
      colorHover: '#000',
      borderColor: '#000',
      borderColorHover: '#000',
    },
    primary: {
      backgroundColor: colors.primary,
      backgroundColorHover: colors.primaryLight,
      color: '#fff',
      colorHover: '#fff',
      borderColor: colors.primary,
      borderColorHover: colors.primaryLight,
    },
    secondary: {
      backgroundColor: '#35363a',
      backgroundColorHover: '#1f1f1f',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#35363a',
      borderColorHover: '#545454',
    },
    terciary: {
      backgroundColor: colors.terciary,
      backgroundColorHover: colors.terciaryLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.terciary,
      borderColorHover: colors.terciaryLight,
    },
    alert: {
      backgroundColor: '#d00000',
      backgroundColorHover: '#e80000',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#d00000',
      borderColorHover: '#e80000',
    },
    trasparent: {
      backgroundColor: 'trasparent',
      backgroundColorHover: 'trasparent',
      color: '#fff',
      colorHover: '#fff',
      borderColor: 'trasparent',
      borderColorHover: 'trasparent',
    },
    simplePrimary: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: colors.primary,
      colorHover: colors.primaryLight,
      borderColor: '#000',
      borderColorHover: '#000',
    },
  },
  links: {
    settings: {
      active: colors.secondary,
      default: colors.primary,
      hover: '#1d95c5',
    },
    default: {
      color: '#000',
      colorHover: '#000',
    },
    secondary: {
      color: colors.terciary,
      colorHover: colors.terciaryLight,
    },
    primary: {
      color: colors.primary,
      colorHover: colors.primaryLight,
    },
    terciary: {
      color: colors.terciary,
      colorHover: colors.terciaryLight,
    },
  },
  text: {
    align: 'justify',
  },
  title: {
    h1: {
      size: '2rem',
      family: 'Gotham',
    },
    h2: {
      size: '1.8rem',
      family: 'Gotham',
    },
    h3: {
      size: '1.6rem',
      family: 'Gotham',
    },
    h4: {
      size: '1.4rem',
      family: 'Gotham',
    },
    h5: {
      size: '1.2rem',
      family: 'Gotham',
    },
  },
  fonts: {
    family: 'Gotham',
    size: {
      sm: '0.7rem',
      md: '0.875rem',
      lg: '1rem',
      xlg: '1.5rem',
      xxlg: '2rem',
    },
  },
  providers: {
    corp: '#231f20',
    guard: '#25aae1',
    intrepid: '#134363',
    corpexpert: '#231f20',
  },
};

export default theme;
