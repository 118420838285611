import React from 'react';
import UserProfile from '../../../components/user/myProfile';
import Layout from '../../../components/layouts/settingsLayout';

const UserProfilePage = () => {
  return (
    <Layout
      active="profile"
      body={
        <>
          <UserProfile />
        </>
      }
    />
  );
};

UserProfilePage.propTypes = {};

export default UserProfilePage;
