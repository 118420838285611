import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as NotificationServices from '../../../../services/NotificationService';
import { withTranslation } from 'react-i18next';
import NotificationForm from './notificationForm.view';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@smartups-sl/react-utils-components';

class NotificationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      values: null,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      NotificationServices.getNotification(this.props.uuid).then((result) => {
        if (result?.uuid) {
          this.setState({ values: result, edit: true });
        }
      });
    }
  }

  handleSubmit = async (values) => {
    try {
      if (values) {
        let result = undefined;

        if (this.state.edit && this.props.uuid) {
          result = await NotificationServices.updateNotification(values, this.props.uuid);
        } else {
          result = await NotificationServices.addNotification(values);
        }

        let title = '';
        let text = '';
        let icon = '';
        if (result && result === 'OK') {
          if (this.state.edit) {
            title = this.props.t('forms.notification.messages.update.title');
            text = this.props.t('forms.notification.messages.update.success');
            icon = 'success';
          } else {
            title = this.props.t('forms.notification.messages.create.title');
            text = this.props.t('forms.notification.messages.create.success');
            icon = 'success';
          }
        } else {
          title = 'Error';
          text = this.props.t('forms.notification.messages.update.error');
          icon = 'error';
        }

        let okAction = undefined;
        okAction = () => {
          this.props.history.push(PATHS.NOTIFICATION_LIST);
        };
        showDialog(title, text, icon, false, 'OK', false, okAction);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    this.props.history.push(PATHS.NOTIFICATION_LIST);
  };

  render() {
    return (
      <NotificationForm
        edit={this.state.edit}
        initialValues={this.state.values}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
      />
    );
  }
}

NotificationContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NotificationContainer));
