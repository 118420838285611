import styled from 'styled-components';
import { Link } from '@smartups-sl/react-basic-components';

export const HeaderList = styled.div`
  background: #35363a;
  margin-right: 0px;
  margin-left: 0px;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid #35363a;
`;

export const ButtonAnchor = styled.button`
  color: ${({ theme }) => theme.colors.terciary};
  border: 0px;
  background: transparent;
`;

export const ExternalLink = styled(Link)`
  margin-right: 5px;
`;

export const IconInfo = styled.i`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.terciary};
  margin-left: 5px;
`;

export const IconLink = styled.i`
  margin-left: 5px;
`;

export const LinkActionStyled = styled(Link)`
  margin-right: 5px;
  border: 1px solid #ff4363;
  padding: 3px;
  border-radius: 5px;

  &:hover {
    background: #ff4363;
    color: #fff;
  }
`;

export const ValueData = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

export const SubTitleInfo = styled.span`
  font-weight: bold;
  font-size: 1rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;

export const LinkAction2Styled = styled.a`
  margin-right: 5px;
  color: #fff;
  font-size: 0.875rem;

  &:hover {
    color: #fff;
    font-size: 0.875rem;
  }
`;

export const RowText = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.875rem;
`;

export const RowTextUuid = styled.div`
  color: #ccc;
  font-size: 0.6rem;
`;

export const FinalAmount = styled.span`
  color: ${({ theme, signe }) => theme.colors.secondary};
  font-size: 0.875rem;
`;

export const PartialAmount = styled.span`
  color: ${({ theme, signe }) =>
    signe && signe === '-' ? theme.colors.negative : theme.colors.secondary};
  font-size: 0.875rem;
`;

export const FeeAmount = styled.span`
  color: ${({ theme, signe }) =>
    signe && signe === '-' ? theme.colors.negative : theme.colors.secondary};
  font-size: 0.875rem;
`;
