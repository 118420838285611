import styled from 'styled-components';
import { Link } from '@smartups-sl/react-basic-components';

export const LinkActionStyled = styled(Link)`
  margin-right: 5px;
  border: 1px solid #ff4363;
  padding: 3px;
  border-radius: 5px;

  &:hover {
    background: #ff4363;
    color: #fff;
  }
`;

export const LinkAction2Styled = styled.a`
  margin-right: 5px;
  color: #ff4363;
  font-size: 0.875rem;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #ff4363;

  &:hover {
    background: #ff4363;
    color: #fff;
    font-size: 0.875rem;
  }
`;
