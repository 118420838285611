import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputTextFormik, InputSelectFormik } from '@smartups-sl/react-forms-components';
import { FormFilter } from '@smartups-sl/react-complex-components';
import { dataModel, validationDataModel } from './marketingFilter.data.form';
import { LANGUAGES, DOCUMENTS_CATEGORIES } from '../../../../constants/index';

const MarketingFilterForm = (props) => {
  const { t } = useTranslation();
  const { setFilters } = props;

  return (
    <>
      <FormFilter
        dataModel={dataModel}
        validationDataModel={validationDataModel}
        title={t('forms.marketing.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.marketing.buttons.filter')}
        textButtonClean={t('forms.marketing.buttons.clean')}
        formName="MarketingFilterForm"
        classNamefilterContainer="dark-filter-container"
        variantTitle="primary"
        animationArrowClassname="filter-arrow"
      >
        <div className="row">
          <div className="col-12">
            <Field
              id="name"
              className="form-control"
              labelField={t('forms.marketing.labels.name')}
              name="name"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
        </div>
        {}
        <div className="row">
          <div className="col-6">
            <Field
              id="category"
              labelField={t('forms.marketing.labels.category')}
              name="category"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={DOCUMENTS_CATEGORIES.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
            />
          </div>
          <div className="col-6">
            <Field
              id="lang"
              labelField={t('forms.marketing.labels.lang')}
              name="lang"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={LANGUAGES.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
            />
          </div>
        </div>
      </FormFilter>
    </>
  );
};

MarketingFilterForm.propTypes = {
  setFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default MarketingFilterForm;
